$date-picker-font-size: 14px;

.lc-input {
    .lc-input-label {
        font-weight: 500;
    }

    .MuiInputAdornment-root {
        .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
        }
    }

    &-switch {
        &-input {
            margin: 0;
        }
    }
}

.MuiPickersPopper-root {
    z-index: 9999 + 1 !important;

    .MuiPickersCalendarHeader-monthTitleContainer {
        overflow: visible;

        .MuiTypography-subtitle1 {
            font-size: $date-picker-font-size;
        }
    }

    .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
    }

    .MuiPickersCalendarHeader-yearSelectionSwitcher {
        margin-top: -5px;
    }

    .MuiPickersCalendar-daysHeader {
        .MuiTypography-caption {
            font-size: $date-picker-font-size;
        }
    }

    .MuiPickersDay-root {
        font-size: $date-picker-font-size;
    }

    .MuiPickersYear-yearButton {
        font-size: $date-picker-font-size;
    }

    .MuiPickersDay-root,
    .MuiPickersYear-yearButton {
        &.Mui-disabled {
            color: rgba(0, 0, 0, 0.3);
            pointer-events: none;
        }
    }
}
