@import '../../css/variables';
@import '../../css/mixins';

.question-definition {
    &-list {
        position: relative;
        margin-bottom: 40px;
        div.tags {
            color: white;
            height: 22px;
            font-size: small;
            font-weight: bold;
            position: absolute;
            top: -8px;
            padding: 0px 4px;
            text-align: left;

            span {
                background-color: #0154FE;
                border-radius: 4px;
                margin-right: 10px;
                padding: 4px 8px;
            }

            span.darker {
                background-color: black;
            }

            span.last {
                color: black;
                background-color: whitesmoke;
                border: 1px solid #0154FE;
            }
        }
    }
}