@mixin column-grid($count: 2, $gap: '32px') {
    display: grid;
    grid-template-columns: repeat(#{$count}, 1fr);
    gap: #{$gap};
}

@mixin auto-column-grid($gap: '32px', $min: '0px', $max: '1fr') {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(#{$min}, #{$max}));
    gap: #{$gap};
}
