@import '../../../css/variables';
@import '../../../css/mixins';

.lc-grid {
    @include column-grid($count: 12, $gap: '8px');
    margin-bottom: 8px;
    width: 100%;

    @media #{$mq-min-xs} {
        gap: 16px;
        margin-bottom: 16px;
    }
    @media #{$mq-min-lg} {
        gap: 20px;
        margin-bottom: 20px;
    }
    @media #{$mq-min-xl} {
        gap: 24px;
        margin-bottom: 24px;
    }

    &__column {
        @each $column-span in (3, 4, 6, 8, 12) {
            &--#{$column-span} {
                grid-column: span #{$column-span};
            }
        }

        @each $size, $media-query in $media-queries {
            @each $column-span in (3, 4, 6, 8, 12) {
                &--#{$size}-#{$column-span} {
                    @media #{$media-query} {
                        grid-column: span #{$column-span};
                    }
                }
            }
        }
    }
}
