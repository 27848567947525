@import '../../css/functions';
@import '../../css/variables';

.layout {
    &-wrapper {
        display: grid;

        grid-template-columns: auto;
        grid-template-rows: $top-bar-height auto;
        grid-template-areas:
            'header'
            'content';

        @media #{$mq-min-md} {
            grid-template-columns: 320px auto;
            grid-template-areas:
                'header header'
                'sidebar content';
        }

        &-full-page {
            grid-template-columns: auto;
            grid-template-rows: auto;
            grid-template-areas: 'content';
        }
    }

    &-sidebar {
        z-index: $z-index-sidebar;
        position: relative;
        display: none;
        grid-area: sidebar;
        background-color: color('white');
        box-shadow: 4px 0 4px rgba(43, 45, 55, 0.01);

        @media #{$mq-min-md} {
            position: fixed;
            display: block;
            width: 320px;
            height: 100%;
            max-height: 100vh;
            padding-top: $top-bar-height;
            overflow-y: auto;
        }
    }

    &-header {
        z-index: $z-index-header;
        grid-area: header / header;
        position: relative;
        background-color: color('white');
        z-index: 1301; // above the side-nav-drawer
    }

    &-drawer {
        z-index: $z-index-drawer;

        .lc-drawer-content {
            padding: $top-bar-height 0 0 0;
        }
    }

    &-content {
        // Note: z-indexes on layout level components can sometimes hide globally positioned tooltips / elements
        grid-area: content;
        position: relative;
        padding: 8px;
        background-color: color('lightest-gray');

        // These media queries were causing the issues in the scroll.
        // @media #{$mq-min-xs} {
        //     padding: 16px;
        // }

        // @media #{$mq-min-sm} {
        //     padding: 20px;
        // }

        // @media #{$mq-min-md} {
        //     padding: 24px;
        // }

        // @media #{$mq-min-lg} {
        //     padding: 32px;
        // }

        // @media #{$mq-min-xl} {
        //     padding: 40px;
        // }
    }
}

// Global Drawer Styles
.lc-drawer .lc-drawer-content {
    padding-top: $top-bar-height + 16px;
}