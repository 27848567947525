@import '../../../css/functions';

.wysiwyg {
    position: relative;
    left: 0px;

    &-character-limit {
        z-index: 2;
        position: absolute;
        right: 44px;
        bottom: 16px;
        font-size: 10px;
        color: color('bluegray');
        pointer-events: none;
    }

    &-resize {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 520px;
        bottom: 12px;
        width: 24px;
        height: 24px;
        background: #fff;
        pointer-events: none;
        border-radius: 7px;
        color: color('bluegray');
    }

    &-editor {
        .ql-picker {
            z-index: 100;
        }

        .ql-toolbar {
            padding: 4px;
            background: color('lightest-gray');
            border-top-left-radius: var(--border-radius);
            border-top-right-radius: var(--border-radius);
            border-color: color('lighter-gray');
            width: 550px;

            .ql-formats {
                margin-right: 4px;
            }

            button {
                padding: 3px;
            }
        }

        .ql-tooltip {
            left: 8px !important;
        }

        .ql-container {
            border-bottom-right-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
            border-color: color('lighter-gray');
            padding: 16px;
            width: 550px;
            position: relative;
        }

        .ql-editor {
            min-height: 240px;
            resize: vertical;
            position: relative;
            padding: 0;
            width: 100%;
            height: 240px;
            @import '../../../css/partials/email-template';
        }
    }
}