@import '../../css/functions';
@import '../../css/mixins';
@import '../../css/variables';

.messages {
    &-page.app-page-wrapper {
        min-height: calc(100vh - 72px);
        max-height: calc(100vh - 72px);
    }

    position: relative;

    &-empty {
        color: color('gray') !important;

        .app-list-empty-title {
            color: inherit !important;
        }
    }

    &-list,
    &-thread {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: color('white');
        transition: all 0.3s cubic-bezier(0.76, 0, 0.24, 1);
    }

    &-list {
        position: relative;
        opacity: 1;
        visibility: visible;
    }

    &-thread {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateY(40px);
        min-height: calc(100vh - 194px);
        max-height: calc(100vh - 194px);
    }

    &-message-selected &-list {
        position: absolute;
    }

    &-message-selected &-thread {
        position: relative;
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    @media #{$mq-min-lg} {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-areas: 'messages-list message-thread';

        &-list,
        &-thread {
            position: relative !important;
            transform: translateX(0) !important;
            opacity: 1 !important;
            visibility: visible !important;
        }

        &-list {
            grid-area: messages-list;
        }

        &-thread {
            position: relative;
            grid-area: message-thread;
        }
    }

    &-tabs {
        height: 100%;

        @media #{$mq-min-lg} {
            border-right: 2px solid color('lighter-gray');
        }

        .MuiTabs-root {
            box-shadow: none;

            .MuiTab-root {
                border-bottom: solid 2px color('light-gray');
                border-radius: 0 !important;
            }
        }

        [id*='tabpanel-'] {
            padding-top: 0 !important;
        }
    }

    &-modal-actions {
        justify-content: flex-end;
    }
}

.threads {
    display: flex;
    flex-direction: column;

    &-list {
        overflow-y: auto;
        height: 100%;
        padding: 0 8px;
        margin: 0;
        min-height: calc(100vh - 330px);
        max-height: calc(100vh - 330px);
    }

    &-search {
        flex: 0;
        width: auto;
        margin: 0 0;
        padding: 20px 24px 8px;

        .lc-input {
            margin-bottom: 0;
        }
    }

    &-item {
        margin: 0 -8px;
    }

    &-list-item {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas:
            'avatar top-row'
            'avatar bottom-row';
        padding: 12px;
        margin: 4px;
        cursor: pointer;
        border-radius: var(--border-radius);
        transition: all 0.15s ease-in-out;
        background-color: transparent;
        width: 99%;
        gap: 0 12px;

        &-top-row {
            grid-area: top-row;
            display: flex;
            justify-content: space-between;
            gap: 0 4px;
        }

        &-bottom-row {
            grid-area: bottom-row;
            display: flex;
            justify-content: space-between;
        }

        &-avatar {
            grid-area: avatar;
            place-self: center;
            width: 40px;
            height: 40px;
            font-size: 16px;
        }

        &-customer-name {
            font-size: 16px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-avatar,
        &-customer-name,
        &-date,
        &-message {
            color: color('text-color');
        }

        &-date {
            font-size: 12px;
        }

        &-message {
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 95%;
            text-align: left;
        }

        &-unread-count {
            width: 20px;
            height: 20px;
            margin: auto;
            border-radius: 50%;
            background-color: color('primary_color');
            color: color('white');
            text-align: center;
            font-weight: bold;
        }

        &.unread {
            &-avatar {
                color: color('black');

                .lc-icon {
                    color: color('dark-gray');
                }
            }

            &:hover,
            &.selected {
                .lc-icon {
                    color: color('white');
                }
            }
        }

        &:hover,
        &.selected {
            color: color('black');
            background-color: color('lightest-gray');
        }

        &:hover &-avatar,
        &.selected &-avatar {
            color: color('black');
            background-color: color('lighter-gray');
        }

        &.selected &-avatar {
            color: color('white');
            background-color: color('primary_color');
        }

        &.read &-customer-name {
            font-weight: 500;
        }
    }
}

.thread-header {
    border-bottom: solid 2px color('lighter-gray');
    display: flex;
    align-items: center;
    padding: 16px;

    @media #{$mq-min-md} {
        padding: 24px;
    }

    &-back {
        margin-right: 8px;

        @media #{$mq-min-lg} {
            display: none;
        }
    }

    &-customer {
        display: grid;
        grid-template-areas:
            'avatar name'
            'avatar phone';

        &-avatar {
            display: none;

            @media #{$mq-min-md} {
                display: flex;
                grid-area: avatar;
                width: 52px;
                height: 52px;
                font-size: 24px;
                margin-right: 12px;
            }
        }

        &-name {
            grid-area: name;
            font-weight: 700;
            font-size: 18px;

            @media #{$mq-min-md} {
                font-size: 22px;
            }
        }

        &-phone {
            grid-area: phone;
            font-size: 12px;
            color: color('darker-gray');

            @media #{$mq-min-md} {
                font-size: 14px;
            }
        }

        &.no-name {
            display: flex;
            align-items: center;

            .thread-header-customer-phone {
                font-weight: 700;
                font-size: 18px;
            }
        }
    }
}

.thread-create-lead-button {
    height: 40px;
    padding: 0 16px;
    margin-right: 4px;

    @media #{$mq-max-sm} {
        padding-right: 0;

        .lc-button-content {
            display: none;
        }
    }

    @media #{$mq-min-md} {
        margin-right: 8px;
    }
}

.thread-actions {
    margin: auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-item {
        display: none;

        @media #{$mq-min-md} {
            display: block;
        }
    }

    &-menu {
        padding: 8px 0;

        &-item {
            padding: 8px 16px;

            &-lead,
            &-archive {
                display: block;

                @media #{$mq-min-md} {
                    display: none;
                }
            }
        }

        .lc-icon {
            margin-right: 12px;
        }
    }
}

.messages-modal-name-fields {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    .lc-input-wrapper {
        width: 100%;
    }
}

.new-messages {
    overflow-y: scroll;

    .message-conversation {
        border-radius: var(--border-radius);
        overflow-y: auto;
        min-height: calc(100vh - 356px);
        max-height: calc(100vh - 356px);

        @media #{$mq-min-md} {
            min-height: calc(100vh - 392px);
            max-height: calc(100vh - 392px);
        }
    }

    &.no-consent {
        min-height: calc(100vh - 352px);
        max-height: calc(100vh - 352px);

        .message-conversation {
            min-height: calc(100vh - 420px);
            max-height: calc(100vh - 420px);
        }

        @media #{$mq-min-md} {
            min-height: calc(100vh - 404px);
            max-height: calc(100vh - 404px);

            .message-conversation {
                min-height: calc(100vh - 380px);
                max-height: calc(100vh - 380px);
            }
        }
    }

    .no-consent-warning {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin: 16px;
        padding: 16px 24px;
        background-color: color('lightest-red');
        border: 1px solid color('red');
        border-radius: var(--border-radius);

        .lc-icon {
            color: color('red');
        }
    }
}

.compose-message-form {
    display: flex;
    gap: 12px;
    padding: 12px;
    border-top: 1px solid color('lighter-gray');

    .lc-input {
        margin-bottom: 0;

        &-wrapper {
            width: 100%;
        }
    }

    &-send-btn.no-consent {
        background-color: color('darker-gray') !important;
        border-color: color('darker-gray') !important;
    }
}

.thread-loading {
    min-height: calc(100vh - 420px);
    max-height: calc(100vh - 420px);

    text-align: center;
    margin-top: 32px;

    p {
        color: var(--text-color-light);
        margin-top: 16px;
        font-size: larger;
    }
}
