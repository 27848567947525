.email-preview {
    // STYLE FIXES FOR EMAIL PREVIEW
    @import '../../css/partials/email-template';

    td {
        min-width: unset;
        overflow-wrap: unset;
        text-align: unset;

        &[align='center'] > table {
            width: 100%;

            img {
                width: inherit !important;
                margin: 0 auto;
                max-width: 100%;
            }
        }
    }

    button {
        table,
        tbody,
        tr,
        td {
            cursor: pointer !important;
        }
    }

    // Note: reset padding-top for preview within the modal
    .modal & {
        padding-top: 16px;
    }

    // STYLES INCLUDED IN MJML EMAILS
    table,
    td {
        border-collapse: collapse;
    }

    img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
    }

    @media only screen and (min-width: 480px) {
        .mj-column-per-100 {
            width: 100% !important;
            max-width: 100%;
        }

        .mj-column-px-50 {
            width: 50px !important;
            max-width: 50px;
        }

        .mj-column-per-80 {
            width: 80% !important;
            max-width: 80%;
        }

        .mj-column-per-71-5 {
            width: 71.5% !important;
            max-width: 71.5%;
        }

        table.mj-full-width-mobile {
            width: 100% !important;
        }

        td.mj-full-width-mobile {
            width: auto !important;
        }
    }
}
