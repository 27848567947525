@import '../../../../css/variables';
@import '../../../../css/functions';

.app-header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;

    &-primary,
    &-simple {
        padding: 16px;
        padding-left: 24px;
        background-color: color('white');
        border-bottom: 4px solid color('lighter-gray');
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media #{$mq-min-md} {
            padding: 24px;
            padding-left: 32px;
        }
    }

    &-secondary {
        padding-bottom: 16px;

        @media #{$mq-min-xs} {
            padding-bottom: 20px;
        }
        @media #{$mq-min-sm} {
            padding-bottom: 24px;
        }
        @media #{$mq-min-md} {
            padding-bottom: 32px;
        }
    }

    &-simple {
        width: 100%;
        padding: 16px;
        padding-left: 24px;
        margin-bottom: 8px;
        border: none;
        border-radius: 8px;
        box-shadow: var(--box-shadow-light);

        @media #{$mq-min-xs} {
            margin-bottom: 16px;
        }
        @media #{$mq-min-lg} {
            margin-bottom: 20px;
        }
        @media #{$mq-min-xl} {
            margin-bottom: 24px;
        }

        @media #{$mq-min-md} {
            padding: 16px;
            padding-left: 32px;
        }
    }

    &-title {
        margin: 0;
    }

    &-back {
        margin-left: -8px;
        margin-right: 8px;
    }

    &-icon {
        margin-right: 16px;
    }
}
