@import '../../../css/functions';
@import '../../../css/variables';

.demo-mode-alert {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    border-radius: var(--border-radius);
    background-color: color('yellow');
    box-shadow: var(--box-shadow-light);

    &-icon {
        padding: 0 16px;

        .lc-icon {
            color: color('white');
        }
    }

    &-content {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        background-color: color('white');
        padding: 12px;
        padding-left: 16px;
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        align-items: center;
        gap: 12px;
    }

    @media #{$mq-max-sm} {
        .action-list {
            justify-content: start;
        }
    }

    &-preview-button {
        @media #{$mq-max-sm} {
            padding-right: 4px;

            .lc-button-content {
                display: none;
            }
        }
    }
}
