@import './colors';

:root {
    --primary-default-color: var(--primary_color, #{$blue});
    --primary-dark-color: var(--primary_color_dark, #{$dark-blue});
    --primary-text-color: var(--primary_button_text_color, white);

    --accent-default-color: var(--secondary_color, #{$lighter-blue});
    --accent-dark-color: var(--secondary_color_dark, #{$light-blue});
    --accent-text-color: var(--secondary_button_text_color, white);

    --success-default-color: #48bb78;
    --success-dark-color: #2f855a;
    --success-text-color: white;

    --warn-default-color: #ed8936;
    --warn-dark-color: #dd6b20;
    --warn-text-color: white;

    --danger-default-color: #e53e3e;
    --danger-dark-color: #c53030;
    --danger-text-color: white;

    --active-default-color: #5bd067;
}
