@import './variables';
@import './mixins';
@import './functions';

.full-width {
    width: 100%;
}

.flex-row {
    display: flex;
    align-items: center;
}

.flex-spacer {
    flex: 1;
}

.unstyled-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.borderless {
    border: none !important;
}

.text {
    color: #282d30;
    margin: 0;
    line-height: 1.2;
    font-size: 14px;

    &-light {
        @extend .text;
        color: #727688;
    }

    &-small {
        @extend .text-light;
        font-size: 10px;
    }
}

.bold {
    font-weight: 700;
}

.truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.app-title {
    font-weight: bold;
    color: #282d30;
    line-height: 1.5;
    font-size: 20px;
}

.field-group {
    &-content {
        @media #{$mq-min-lg} {
            @include column-grid();
        }
    }

    &-button-full-width {
        width: 100%;
        margin-bottom: 20px;
    }

    &-helper-text {
        font-size: 12px;
        color: color('dark-bluegray');
        margin-bottom: 20px;

        &+& {
            margin-top: -12px;
        }

        span {
            padding: 1px 4px;
            border-radius: 4px;
            background-color: #ececec;
        }
    }
}

.bullet {
    display: inline-block;
    margin: 0 6px;

    &::before {
        content: '•';
    }
}

.field-row {
    @media #{$mq-min-lg} {
        @include auto-column-grid($gap: '24px');
    }
}

.help-icon-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .MuiFormControl-root.lc-input {
        margin-bottom: 0;
    }
}

.color {
    $colors: (black: 'lc-color-black',
        white: 'lc-color-white',
        primary: 'lc-color-primary',
        accent: 'lc-color-accent',
        success: 'lc-color-success',
        warning: 'lc-color-warning',
        danger: 'lc-color-danger',
        gray-darkest: 'lc-color-gray-darkest',
        gray-darker: 'lc-color-gray-darker',
        gray-dark: 'lc-color-gray-dark',
        gray: 'lc-color-gray',
        gray-light: 'lc-color-gray-light',
        gray-lighter: 'lc-color-gray-lighter',
        gray-lightest: 'lc-color-gray-lightest',
        bluegray: 'lc-color-bluegray',
        blue: 'blue',
        green: 'green',
        dark-red: 'dark-red'
    );

    @each $key,
    $value in $colors {
        &-#{"" + $key} {
            color: color($value) !important;
        }
    }
}