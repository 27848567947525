@import '../../../../css/variables';
@import '../../../../css/functions';

.app-page {
    flex: 1 1 auto;
    font-size: 14px;
    background: white;
    border-radius: var(--border-radius);

    // Note: we need a max-width here to prevent issues with table overflow.
    @media #{$mq-min-md} {
        max-width: calc(100vw - 72px);
    }

    &-wrapper {
        display: flex;
        // Note: in the future when we can remove the bootstrap container around the main content, we can update this.
        // min-height to just be 100vh
        min-height: calc(100vh - 136px);
    }

    &.loading {
        padding: 32px;
        min-height: 500px;
    }

    hr {
        height: 1px;
        background-color: color('lighter-gray');
        border: none;
        margin: 24px 0;
    }
}
