@import '../../../../css/functions';
@import '../../../../css/mixins';
@import '../../../../css/variables';

.integrations-page {
    &__row {
        display: flex;
    }

    &__logo {
        display: flex;
        margin-right: 24px;
        align-items: center;
        max-width: 90px;

        img {
            height: auto;
            width: 100%;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__title {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        @media #{$mq-min-md} {
            font-size: 20px;
        }
    }

    &__description {
        margin: 0;
        font-size: 12px;
        line-height: 1.3;
        color: color('dark-bluegray');

        @media #{$mq-min-md} {
            font-size: 14px;
        }
    }

    &__setup {
        min-width: 140px;
    }
}
