@import '../../../css/variables';
@import '../../../css/functions';
@import '../../../css/mixins';

.lc-panel {
    border-radius: 8px;
    padding: 24px;
    margin: 24px 0;
    color: color('black');

    @media #{$mq-min-lg} {
        padding: 32px;
    }

    &--default {
        background: color('lightest-gray');
    }
    &--info {
        border: solid 1px color('blue');
        background: color('blue', 0.08);
    }
    &--success {
        border: solid 1px color('lc-color-success');
        background: color('lc-color-success', 0.12);
    }
    &--warning {
        border: solid 1px color('lc-color-warning');
        background: color('lc-color-warning', 0.1);
    }
    &--danger {
        border: solid 1px color('lc-color-danger');
        background: color('lc-color-danger', 0.1);
    }
}
