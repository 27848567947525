.step-checklist {
    margin-top: 24px;
    font-size: 18px;
    font-weight: bold;
    list-style: none;

    &-item {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        &-check {
            margin-right: 8px;
            height: 24px;
            color: #a0a5ba;
        }

        &.completed {
            color: #a0a5ba;
        }

        &.current {
            .step-checklist-item-check {
                color: #3fc053;
            }
        }
    }
}
