@import '../../css/variables';
@import '../../css/helpers';
@import '../../css/functions';

.email-editor {
    &-video-grid {
        display: flex;
        max-width: fit-content;
        div {
            img {
                width: 200px;
            }
            margin: 12px;
        }
    }
    &-edit-name {
        &-form {
            display: flex;
            align-items: flex-start;

            input {
                min-width: 240px;
            }
        }

        &-button {
            position: relative;
            margin-left: 4px;

            svg {
                height: 20px;
                width: 20px;
            }
        }

        &-input {
            margin-bottom: 0 !important;
        }

        &-save-button,
        &-cancel-button {
            margin-left: 8px;
        }
    }
    &-edit-app {
        &-header {
            flex-wrap: wrap;
            justify-content: flex-end;

            .editing & {
                display: none;

                @media #{$mq-min-lg} {
                    display: flex;
                }
            }

            @media #{$mq-min-lg} {
                flex-wrap: nowrap;
            }
        }

        &-test {
            position: relative;

            &-button {
                margin-left: 16px;
                min-width: 115px;
            }

            &-hint {
                display: block;
                position: absolute;
                right: 0;
                padding-top: 4px;
                text-align: right;
                width: calc(100vw - 64px);
                overflow-wrap: break-word;
                color: color('gray');
            }
        }

        &-content {
            display: grid;
            gap: 32px;

            grid-template-areas:
                'email-details'
                'email-preview';

            @media #{$mq-min-lg} {
                grid-template-columns: minmax(320px, 2fr) 3fr;
                grid-template-areas: 'email-details email-preview';
            }

            .editing & {
                grid-template-areas: 'email-form';

                .email-editor-edit-app-preview {
                    display: none;
                }

                @media #{$mq-min-lg} {
                    grid-template-areas: 'email-form email-preview';

                    .email-editor-edit-app-preview {
                        display: block;
                    }
                }
            }
        }

        &-details {
            grid-area: email-details;
        }

        &-form {
            grid-area: email-form;
        }

        &-preview {
            grid-area: email-preview;
        }
    }

    &-preview {
        padding: 16px;
        background: color('lightest-gray');
        min-width: 320px;
        border-radius: var(--border-radius);

        &-hint {
            color: color('gray');
        }

        @media #{$mq-min-lg} {
            padding-top: 32px;
        }

        &-section {
            position: relative;
            margin: 0px auto;
            max-width: 568px;
            min-height: 48px;

            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                background: transparent;
                margin-bottom: 0;
            }

            &-info {
                color:darkgray;
                margin: 20px;
                padding: 0px 40px;
            }
        }

        &-button {
            position: relative;
            width: 100%;
            display: block !important;
            padding: 0px;

            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                pointer-events: none;
                border: solid 2px transparent !important;
            }

            &.active,
            &:hover,
            &:focus {
                outline: none !important;

                &::after {
                    border-color: color('lightest-blue') !important;
                }
            }
        }

        &-icon {
            color: color('gray');
            position: absolute;
            top: 12px;
            right: 12px;
        }

        &-hide-icon {
            color: color('gray');
            position: absolute;
            top: 12px;
            right: 40px;
        }
    }

    &-footer {
        &-cancel {
            margin-right: 16px;
        }
    }
}
