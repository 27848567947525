.as-data-grid-list-options {
    div {
        border: 1px solid black;
        margin-right: 40px;
        display: inline;
    }
}

.top-toolbar {
    display: inline-flex;
}

.form-holder-block {
    div.bottom-toolbar {
        display: inline-flexbox;
        width: 400px;
        .lc-input-wrapper {
            width: 500px;
        }
    }
}

@import '../../css/functions';
@import '../../css/helpers';

.email-viewer-modal {
    width: 920px;
    max-width: 100%;
    min-width: 0;

    &-content {
        display: flex;
    }
}

.segment-input {
    border: 1px solid black;
    width: 400px;
}

.filters {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    .lc-input-text {
        border: 1px solid silver;
        border-radius: 6px;
    }

    .lc-input-checkbox {
        width: 300px;
    }

    ul {
        list-style-type: none;
    }

    .filter-div {
        width: 100%;
        .filter-cell {
            min-width: none;
            display: flexbox;
        }
    }

    .filter-card {
        width: 100%;
        border: 1px dashed silver;
        margin: 4px;
    }

    .half-filter-card {
        border: 1px dashed silver;
    }

    .filter-submit {
        margin-top: 12px;
    }
}

.question-card-picklist {
    border: 1px dotted silver;
    border-radius: 6px;
    background-color: aliceblue;
    padding: 12px;
}

.dns-list {
    margin-bottom: 20px;
    &-txt-box {
        width: 250px;
        height: 50px;
        overflow-x: auto;
    }
    &-item,
    &-header {
        display: grid;
        grid-auto-rows: auto;
        grid-row-gap: 8px;

        @media (min-width: 900px) {
            grid-template-columns: 1fr 2fr 2fr 1fr;
        }
    }

    &-header {
        @media (max-width: 899px) {
            display: none;
        }
    }

    &-item {
        > div {
            min-width: 0;
        }

        &-field {
            display: grid;
            grid-template-columns: 1fr 2fr 2fr 1fr;

            @media (min-width: 900px) {
                display: block;
            }

            &-label {
                color: color('bluegray');
                padding-right: 16px;

                @media (min-width: 900px) {
                    display: none;
                }
            }

            &-error {
                color: color('red');
                font-weight: 700;
            }
        }

        &-actions {
            text-align: right;
        }

        &-wrapper {
            & + & {
                margin-top: 12px;
            }
        }

        &-error {
            color: color('red');
            margin-top: 8px;
            margin-bottom: 12px;
        }

        &-has-error {
            border-color: color('red') !important;
        }
    }
}
