@import '../../css/functions';

.public {
    $card-width: 480px;
    $form-width: 320px;
    $reset-card-width: 489px;

    &-page {
        width: 100%;
        min-height: 100vh;
        background: color('lightest-gray');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-card {
        background: white;
        padding: 32px;
        border-radius: var(--border-radius) !important;
        width: $card-width;
        max-width: calc(100vw - 32px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-branding {
        color: color('primary_color');
        margin: 32px 16px 48px;

        img {
            width: 100%;
            height: auto;
            max-width: $form-width;
        }
    }

    &-form {
        max-width: $form-width;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 48px;

        &-error {
            margin: -8px 0 16px;
            width: 100%;
            color: #f44336;
            text-align: center;
        }

        &-valid {
            margin: -8px 0 16px;
            width: 100%;
            color: color('green');
            text-align: center;
        }
    }

    &-help {
        margin: 32px 0;

        a {
            font-weight: bold;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-password {
        &-strength {
            &-indicator {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
            }

            &-label {
                font-weight: bold;
            }

            &-bar {
                width: 100%;
                height: 10px;
                background-color: color('lightest-gray');
                border-radius: 5px;
                margin-bottom: 20px;
            }
        }

        &-bar {
            &-empty {
                width: 0;
            }

            &-weak {
                height: 10px;
                width: 33%;
                background-color: #f44336;
                border-radius: 5px;
            }

            &-ok {
                height: 10px;
                width: 66%;
                background-color: color('dark-yellow');
                border-radius: 5px;
            }

            &-strong {
                height: 10px;
                width: 100%;
                background-color: color('green');
                border-radius: 5px;
            }
        }

        &-weak {
            color: #f44336;
        }
    }

    &-reset {
        &-card {
            width: $reset-card-width;
            border-radius: 12px;
            padding: 62px;
            margin-bottom: 48px;

            h2 {
                font-size: 16px;
                text-align: center;
            }

            p {
                text-align: center;
            }

            svg {
                display: block;
                margin: 0 auto;
            }
        }

        p {
            width: 378px;
            text-align: center;
            margin: 0 auto;
        }
    }
}
