.Draggable {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 250ms ease;
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0);

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        min-height: 54px;
        flex-shrink: 1;
        appearance: none;
        outline: none;
        border: 0;
        padding: 8px 18px;
        background-color: #181a22;
        border-radius: 5px;
        box-shadow: var(--box-shadow);
        transform: scale(var(--scale, 1));
        transition: transform 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22), box-shadow 300ms ease;
    }

    &:not(.handle) {
        button {
            touch-action: none;
            cursor: grab;

            &:focus-visible:not(.active &) {
                box-shadow: 0 0 0 3px #4c9ffe;
            }
        }
    }

    &.handle {
        button {
            --action-background: rgba(255, 255, 255, 0.1);

            > svg {
                margin-right: 5px;
            }

            > button {
                margin-right: -10px;
            }
        }
    }

    img {
        width: 140px;
        user-select: none;
        pointer-events: none;
    }

    label {
        display: block;
        flex-shrink: 1;
        padding: 10px;
        transition: opacity 250ms ease;

        text-align: center;
        font-size: 1rem;
        font-weight: 300;
        color: #8d8d8d;
        user-select: none;
        cursor: url('/cursor.svg'), auto;

        animation-name: pulse;
        animation-duration: 1.5s;
        animation-delay: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
        animation-direction: alternate;
    }

    &.dragging {
        z-index: 1;
        transition: none;

        * {
            cursor: grabbing;
        }

        button {
            --scale: 1.06;
            --box-shadow: -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);

            &:focus-visible {
                --box-shadow: 0 0px 10px 2px #4c9ffe;
            }
        }

        label {
            animation: none;
            opacity: 0;
        }
    }

    &.dragOverlay {
        button {
            animation: pop 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
        }
    }
}

@keyframes pulse {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pop {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(var(--scale));
        box-shadow: var(--box-shadow);
    }
}
