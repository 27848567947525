// Keep --clx_default vars on the :root for the agency theme default properties configuration
// Other vars need to be placed on the body (not the :root) due to
// specificity conflicts with the component library
:root,
body {
    --clx_default_primary_color: #{$blue};
    --clx_default_secondary_color: #{$faded-blue};
    --clx_default_primary_button_text_color: #{$white};
    --clx_default_secondary_button_text_color: #{$white};

    --primary_color: #{$blue};
    --primary_color_dark: #{$dark-blue};

    --secondary_color: #{$faded-blue};
    --secondary_color_dark: #{$dark-faded-blue};

    --primary_button_text_color: #{$white};
    --secondary_button_text_color: #{$white};

    --font-family-sans: clx-gilroy, sans-serif;

    --text-color: #{$text-color};
    --text-color-light: #{$text-color-light};

    --white: #{$white};
    --black: #{$black};

    --lightest-red: #{$lightest-red};
    --light-red: #{$light-red};
    --red: #{$red};
    --dark-red: #{$dark-red};

    --green: #{$green};
    --dark-green: #{$dark-green};

    --yellow: #{$yellow};
    --dark-yellow: #{$dark-yellow};

    --purple: #{$purple};
    --dark-purple: #{$dark-purple};

    --lightest-blue: #{$lightest-blue};
    --lighter-blue: #{$lighter-blue};
    --light-blue: #{$light-blue};
    --blue: #{$blue};
    --dark-blue: #{$dark-blue};

    --faded-blue: #{$faded-blue};
    --dark-faded-blue: #{$dark-faded-blue};

    --lightest-gray: #{$lightest-gray};
    --lighter-gray: #{$lighter-gray};
    --light-gray: #{$light-gray};
    --gray: #{$gray};
    --dark-gray: #{$dark-gray};
    --darker-gray: #{$darker-gray};

    --light-bluegray: #{$light-bluegray};
    --bluegray: #{$bluegray};
    --dark-bluegray: #{$dark-bluegray};

    --app-red: #{$app-red};
    --app-green: #{$green};
    --app-purple: #{$purple};

    --border-radius: 8px;

    --transition-fast: all 0.15s ease-in-out;

    --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    --box-shadow-light: 0px 2px 4px rgba(0, 0, 0, 0.02);

    /*
     * ==========================================
     * Override component library theme variables
     * ==========================================
     */

    --lc-font-family-sans: var(--font-family-sans);

    --lc-color-black: var(--black);
    --lc-color-white: var(--white);

    --lc-color-gray-lightest: var(--lightest-gray);
    --lc-color-gray-lighter: var(--lighter-gray);
    --lc-color-gray-light: var(--light-gray);
    --lc-color-gray: var(--gray);
    --lc-color-gray-dark: var(--dark-bluegray);
    // --lc-color-gray-darker: 64, 64, 64;

    --lc-color-primary: var(--primary_color);
    --lc-color-primary-dark: var(--primary_color_dark);

    --lc-color-accent: var(--secondary_color);
    --lc-color-accent-dark: var(--secondary_color_dark);

    --lc-color-success: var(--green);
    --lc-color-success-dark: var(--dark-green);

    --lc-color-warning: var(--yellow);
    --lc-color-warning-dark: var(--dark-yellow);

    --lc-color-danger: var(--red);
    --lc-color-danger-dark: var(--dark-red);
}
