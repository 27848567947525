// TODO: Remove this once we have reskinned all pages.
.bengal:not(.bengal--new-layout) {
    .app-page-wrapper,
    .home-dashboard,
    .clarity-dashboard,
    .lead-details,
    .lead-view {
        margin: -15px;
        padding: 24px;
        background-color: color('lightest-gray');
        min-height: 100vh;
    }
}
