.modal-new-number {
    .modal-header {
        position: relative;
        padding: 15px 15px 0;
        .close {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .form-group {
        button {
            color: #fff;
        }
    }
}

.modal-header {
    margin: 0;
    padding: 20px 15px 0;
}
