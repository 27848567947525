@import '../../../css/variables';
@import '../../../css/functions';

.side-nav {
    grid-area: side-nav;
    padding: 0 16px 32px;
    border-right: solid 1px color('lighter-gray');
    min-height: calc(100vh - 72px - 16px);

    @media #{$mq-min-md} {
        min-height: calc(100vh - 72px);
        padding-top: 16px;
    }

    &-is-disabled {
        opacity: 0.65;
        pointer-events: none;
    }

    &-back.lc-button-default {
        width: 100%;
        justify-content: start;
        color: color('black');
        background-color: color('lightest-gray');
        border-color: color('lightest-gray');

        &:hover {
            color: color('black');
            background-color: color('lighter-gray');
            border-color: color('lighter-gray');
        }
    }

    &-divider {
        height: 1px;
        margin: 16px 0;
        background-color: color('lighter-gray');
    }

    &-menu {
        padding: 0;
        list-style: none;
        color: color('darker-gray');

        &-item {
            position: relative;
            margin-top: -1px;
            border-radius: 6px;
            background-color: transparent;

            &-button {
                &.lc-button {
                    width: 100%;
                    padding: 16px;
                    padding-right: 56px;
                    font-size: 16px;
                    font-weight: 700;
                    text-align: left;
                    border-radius: 6px;

                    > .lc-button-content {
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }
                }
            }

            &-label {
                flex: 1 1 auto;
            }

            &-count {
                position: absolute;
                top: 16px;
                right: 16px;
                font-size: 12px;
                width: 24px;
                height: 24px;
                color: #fff;
                background-color: color('primary_color');
                border-radius: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-icon.lc-icon,
            &-caret.lc-icon-button {
                flex: none;
            }

            &-icon.lc-icon {
                color: color('bluegray');
                margin-right: 16px;
            }

            &-caret.lc-icon-button.lc-button {
                position: absolute;
                top: 16px;
                right: 16px;
                width: 24px;
                height: 24px;
                min-width: 24px;
                color: color('bluegray');

                &:focus-visible {
                    --tw-ring-color: #{color('gray', 0.5)};
                }
            }

            &:hover &-button.lc-button {
                color: color('black');
                background-color: color('lightest-gray');
            }

            &-is-open,
            &-is-active {
                background-color: color('lightest-gray');
            }

            &-is-open &-button.lc-button,
            &-is-active &-button.lc-button {
                color: color('black');
            }

            &-is-active &-icon.lc-icon {
                color: color('primary_color');
            }

            &-is-open &-caret.lc-icon-button {
                transform: rotate(180deg);
            }
        }
    }

    &-menu &-divider {
        margin-left: 24px;
        margin-right: 24px;
    }

    &-submenu {
        list-style: none;
        padding: 0 16px 16px 44px;

        &-item {
            position: relative;

            &::before {
                position: absolute;
                top: 15px;
                left: -2px;
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: color('light-gray');
                pointer-events: none;
                transition: all 0.15s ease-in-out;
                opacity: 0;
            }

            &-button.lc-button {
                display: block;
                width: 100%;
                padding: 8px 12px;
                color: color('darker-gray');
                text-align: left;
                border-radius: 6px;

                > .lc-button-content {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
            }

            &:hover,
            &-is-active {
                &::before {
                    opacity: 1;
                }
            }

            &-is-active {
                &::before {
                    background-color: color('primary_color');
                }
            }

            &:hover &-button.lc-button,
            &-is-active &-button.lc-button {
                color: color('black');
            }

            &-is-active &-button.lc-button {
                font-weight: 700 !important;
            }
        }
    }

    &-release {
        margin-left: 16px;
        font-size: 10px;
        color: color('darker-gray');

        &-version {
            margin-bottom: 8px;
        }

        &-notes {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
