.breadcrumbs {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    color: color('darker-gray');
    font-size: 12px;
    margin-bottom: 12px;

    &-item {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: none;

        &:last-child {
            font-weight: 700;
            flex: auto;
        }

        & + &:not(:nth-child(2)) {
            padding-left: 20px;

            &::before {
                position: absolute;
                left: 0;
                top: 50%;
                margin-left: 8px;
                content: '›';
                font-weight: 400;
                transform: translateY(-50%);
            }
        }

        &-secondary {
            max-width: 80px;
        }

        &-back {
            padding-right: 8px;

            .lc-icon {
                width: 16px;
                height: 16px;
                margin-top: -2px;
            }
        }
    }

    .lc-button-unstyled {
        padding: 0;
        font-size: inherit;
        max-width: 100%;
        justify-content: start;

        .lc-button-content {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:hover {
            color: color('black');
        }
    }
}
