@import '../../../../css/functions';
@import '../../../../css/mixins';
@import '../../../../css/variables';

.drip-event-card {
    &-text-us-conversation {
        max-height: 440px;
        overflow-y: auto;
        margin: -16px;

        @media #{$mq-min-xs} {
            margin: -20px;
        }
        @media #{$mq-min-sm} {
            margin: -24px;
        }
        @media #{$mq-min-md} {
            margin: -32px;
        }
    }
}
