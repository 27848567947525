@import '../../../css/variables';
@import '../../../css/functions';
@import '../../../css/mixins';

.lc-list-group {
    --list-group-icon-blue: var(--blue);
    --list-group-icon-blue-bg: var(--lightest-blue);
    --list-group-icon-green: var(--green);
    --list-group-icon-green-bg: var(--green);

    @include column-grid($count: 12, $gap: '16px');
    margin: 0;
    padding: 0;

    @media #{$mq-min-sm} {
        gap: 20px;
    }

    @media #{$mq-min-md} {
        gap: 24px;
    }

    &:empty {
        display: none;
    }

    &__item {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        grid-column: span 12;

        &__icon {
            display: inline-flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            border-radius: 8px;

            @each $icon-color in ('blue', 'green') {
                &--#{$icon-color} {
                    color: color('list-group-icon-#{$icon-color}');
                    background: color('list-group-icon-#{$icon-color}-bg', 0.1);
                }
            }
        }

        &__label {
            font-size: 14px;
            color: color('darker-gray');
        }

        &__value {
            font-size: 16px;
            font-weight: 700;
        }

        &--disabled &__icon {
            color: color('bluegray') !important;
            background-color: color('lightest-gray') !important;
        }
    }

    @each $column-count in (1, 2, 3, 4) {
        &--columns-#{$column-count} &__item {
            @media #{$mq-min-lg} {
                grid-column: span #{calc(12 / $column-count)};
            }
        }
    }
}