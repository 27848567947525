@import '../../../css/functions';
@import '../../../css/mixins';
@import '../../../css/variables';

.lead-view {
    &-list-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        .lc-input-wrapper {
            flex-basis: 250px;
        }

        .MuiFormControl-root.lc-input {
            margin-bottom: 0;
        }
    }

    .empty-message h1 {
        font-size: 2em;
        margin: auto;
        text-align: center;
    }

    &-stats {
        &.app-section {
            padding: 0;
        }

        .MuiCircularProgress-root {
            margin-top: 16px;
        }
    }

    .rbc-calendar {
        min-height: 580px;
    }

    .appointment-header {
        text-align: left !important;
    }

    .lc-table td {
        cursor: pointer !important;
    }

    .lc-table tbody tr:nth-child(odd) td {
        &:first-child {
            border-radius: var(--border-radius) 0 0 var(--border-radius);
        }

        &:last-child {
            border-radius: 0 var(--border-radius) var(--border-radius) 0;
        }
    }

    .lead-list-row {
        &-name {
            font-weight: bold;
        }

        &-phone,
        &-company,
        &-touches {
            color: color('dark-gray');
        }

        .missed-appointment {
            text-align: left;
        }

        .appointment-icon {
            .lc-icon {
                margin-right: 8px;
            }

            &.cancelled {
                color: color('red');
            }

            &.passed {
                color: color('dark-gray');
            }
        }

        .upcoming-appointment {
            display: grid;
            grid-template-columns: minmax(auto, 40px) auto;
            grid-template-areas:
                'icon details'
                'icon details';

            &-icon {
                grid-area: icon;
                margin: auto;
                margin-left: 0;
                color: color('green');
            }

            &-details {
                grid-area: details;
                text-align: left;
            }

            &-date {
                color: color('dark-gray');
            }
        }
    }
}
