@import '../../../css/functions';
@import '../../../css/variables';

.bengal {
    .MuiTabs-root {
        box-shadow: var(--box-shadow-light);

        .MuiTab-root {
            font-size: 14px;
            line-height: 1.25;
            padding: 20px 24px;
            opacity: 1;
            color: color('darker-gray');
            border-bottom: none;
            overflow: hidden;

            &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            @media #{$mq-min-md} {
                font-size: 16px;
            }

            &.Mui-selected {
                color: color('primary_color');
            }
        }
    }

    [id*='tabpanel-'] {
        padding-top: 24px;
    }
}
