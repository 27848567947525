.onboarding-success-modal {
    max-width: 500px;
    text-align: center;

    svg {
        display: block;
        margin: 0 auto;
    }

    p {
        margin: 0 auto;
        max-width: 330px;
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 32px;
    }
}
