@import '../../../css/functions.scss';
@import '../../../css/colors.scss';
@import '../../../css/variables.scss';

.message {
    width: 85%;

    @media #{$mq-min-xs} {
        width: 75%;
    }

    @media #{$mq-min-sm} {
        width: 55%;
    }

    @media #{$mq-min-md} {
        width: 45%;
    }

    &-conversation {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px;

        @media #{$mq-min-xs} {
            padding: 12px;
        }
        @media #{$mq-min-sm} {
            padding: 16px;
        }
        @media #{$mq-min-md} {
            padding: 20px;
        }
        @media #{$mq-min-lg} {
            padding: 24px;
        }
    }

    &-bubble {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px 12px 8px;
        border-radius: var(--border-radius);

        &.has-error {
            color: color('black');
            opacity: 0.6;
        }
    }

    &-image-wrapper.has-caption {
        align-self: center;
    }

    &-text {
        font-size: 16px;
    }

    &-timestamp {
        font-size: 12px;
        color: color('dark-gray');
    }

    &.is-outgoing {
        align-self: flex-end;
    }

    &.is-outgoing + &.is-incoming,
    &.is-incoming + &.is-outgoing {
        margin-top: 16px;
    }

    &.is-outgoing &-bubble {
        background-color: color('lightest-gray');
        border-bottom-right-radius: 0%;
        border: solid 1px color('lightest-gray');
    }

    &.is-outgoing &-timestamp {
        align-self: flex-end;
    }

    &.is-incoming {
        align-self: flex-start;
    }

    &.is-incoming &-bubble {
        background-color: color('lightest-blue', 0.15);
        border-bottom-left-radius: 0%;
        border: solid 1px transparent;
    }

    &.is-incoming &-timestamp {
        align-self: flex-start;
    }

    &.has-error &-bubble {
        background-color: transparent;
        border-color: color('dark-red');
    }

    &-error {
        color: color('dark-red');
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 1.15;

        .lc-icon {
            width: 16px;
            margin-right: 4px;
        }
    }
}
