h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    all: unset;
    font-family: var(--font-family-sans);
    display: block;
    font-size: 14px;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.25;
    font-weight: bold;
}

h1 {
    font-size: 20px;
}

h2 {
    font-size: 16px;
}

strong {
    font-weight: bold;
}

a {
    color: color('blue');
    text-decoration: none;
}

a:hover,
a:focus {
    color: color('lighter-blue');
}

ul,
ol {
    padding: 0 0 0 20px;
}

ol {
    list-style-type: decimal;
}

li {
    margin: 0 !important;
    padding: 0 !important;
}

.ql-align-left {
    text-align: left;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}
