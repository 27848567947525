#crm-config__utm-rules {
    margin-bottom: 80px;
}
#crm-rule-manager {
    .crm-rule-manager__list {
        .crm-rule-manager__list-rule {
            margin-bottom: 8px;
            padding: 8px 0;
            border-bottom: 1px solid #eee;
        }
    }
}
