@import '../../../css/functions';

.avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: inherit;
    font-weight: 700;
    line-height: 0;
    color: color('dark-gray');
    background-color: color('lightest-gray');
    transition: all 0.15s ease-in-out;
    overflow: hidden;

    &,
    img {
        width: 28px;
        height: 28px;
    }

    img {
        object-fit: cover;
    }
}
