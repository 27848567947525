@import '../../../css/variables';

.chart-card {
    text-align: center;
    position: relative;
    margin: 0 !important;
    display: flex;
    flex-flow: column;

    @media #{$mq-min-lg} {
        &--pie.app-card {
            grid-column: span 6;
        }
    }

    &__title.title {
        margin: 0 0 32px;
    }

    .lc-time-chart {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: minmax(0, 1fr);
    }

    .lc-chart-legend {
        margin-bottom: 0;
        padding-bottom: 0;

        &-wrapper {
            text-align: left;
        }
    }

    .lc-chart-pie {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        grid-template-columns: minmax(0, 350px) 200px;
        gap: 24px;
        align-items: center;
    }

    .lc-pie-chart {
        &-legend {
            justify-content: flex-start;
            padding: 0;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            &-list {
                margin: 0 auto;
            }

            &-item {
                display: flex;
                margin-right: 0;

                @media (min-width: 860px) {
                    width: 100%;
                }
            }

            &-label {
                width: 180px;
                display: flex;
                align-items: center;
                white-space: break-spaces;

                &-name {
                    font-weight: bold;
                    line-height: 1.2;

                    &::after {
                        content: '';
                    }
                }

                &-value {
                    display: none;
                }

                &-percentage {
                    flex: 1;
                    text-align: right;

                    &::before,
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }

    // TODO: consider moving these styles to the component library.
    .lc-chart-bar {
        .lc-chart-legend {
            margin: -4px -16px;
        }
    }

    .lc-chart-line {
        .lc-chart-legend {
            margin: 8px -8px -8px;
        }
    }

    &__empty {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;

        &__illustration {
            margin: 0 auto 24px;
        }

        &__description {
            font-size: 16px;
        }
    }

    &__actions {
        position: absolute;
        top: 2px;
        right: 2px;

        .lc-icon {
            transition: background 0.25s, color 0.25s;
            color: color('light-gray');
            height: 20px;
            width: 20px;
        }

        .lc-button:hover {
            .lc-icon {
                color: color('dark-gray');
            }
        }
    }
}
