@import '../../../css/functions';
@import '../../../css/mixins';
@import '../../../css/variables';

$mq-break1: 'screen and (min-width: 800px)';
$mq-break2: 'screen and (min-width: 1200px)';

.lead-details {
    &-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media #{$mq-min-lg} {
            gap: 24px;
        }
    }

    &-customer-info {
        @media #{$mq-min-lg} {
            width: 100%;
        }

        &-grid {
            display: flex;
            flex-direction: column;
            @media #{$mq-min-sm} {
                flex-direction: row;
            }
        }
    }

    &-events {
        flex: 1 1 auto;
    }

    &-card {
        &-edit {
            position: absolute;
            top: 8px;
            right: 8px;
        }

        &--customer {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media #{$mq-min-sm} {
                width: 50%;
            }

            .avatar {
                margin-top: 16px;
                height: 72px;
                width: 72px;
                font-size: 34px;
                font-weight: bold;
                color: color('black');
                margin-bottom: 24px;
                border-radius: 8px;
            }

            &-email,
            &-phone {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &--metrics {
            @media #{$mq-min-sm} {
                width: 50%;
            }

            .lc-list-group {
                &__item {
                    &__icon {
                        background-color: color('lightest-gray');

                        @media #{$mq-min-lg} {
                            width: 48px;
                            height: 48px;
                        }
                    }
                }
            }
        }
    }
}
