@import '../../css/variables';
@import '../../css/helpers';
@import '../../css/functions';

.list-segment-editor {
    &-list {
        @extend .unstyled-list;

        &-page-empty {
            display: grid;
            place-items: center;
            padding: 48px 0;
            max-width: 400px;
            margin: 0 auto;
            text-align: center;

            &-title {
                margin: 32px 0 8px;
            }
        }
    }

    &-edit-name {
        &-form {
            display: flex;
            align-items: flex-start;

            input {
                min-width: 240px;
            }
        }

        &-button {
            position: relative;
            margin-left: 4px;

            svg {
                height: 20px;
                width: 20px;
            }
        }

        &-input {
            margin-bottom: 0 !important;
        }

        &-save-button,
        &-cancel-button {
            margin-left: 8px;
        }
    }

    &-content {
        @media #{$mq-min-lg} {
            display: grid;
            gap: 32px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'schedule time-off';
        }
    }

    &-week {
        grid-area: 'schedule';
    }

    &-time-off {
        grid-area: 'time-off';

        &-button-link {
            &:last-child {
                margin-left: 16px;
            }
        }
    }

    &-footer {
        &-cancel {
            margin-right: 8px;
        }
    }
}

.edit-list-segment {
    &-template {
        &-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-select {
                width: 350px;
                margin-right: 12px;
                padding-right: 24px;
            }

            &-name {
                width: 350px;
                margin: 12px;
            }

            &-time-select {
                width: 200px;
                margin-right: 12px;
            }

            &-toggle {
                top: -8px;
                margin-right: 12px;
            }

            &-trash {
                top: -11px;
                margin-right: 12px;
                position: relative;
            }
        }
    }

    &-template {
        width: 100%;

        &-row {
            display: flex;
            align-items: center;

            &-first {
                padding-right: 44px;
            }
        }

        &-delete-button {
            margin-left: 8px;
        }
    }
}
