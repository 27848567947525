
.as-data-grid-list-options {
    div {
        border: 1px solid black;
        margin-right: 40px;
        display: inline;
    }
    
}

.top-toolbar {
    display: inline-flex;
}

.form-holder-block {
    div.bottom-toolbar {
        display: inline-flexbox;
        width: 400px;
        .lc-input-wrapper {
            width: 500px;
        }
    }
}

.segment-input {
    border: 1px solid black;
    width: 400px;
}

.filters {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    .lc-input-text {
        border: 1px solid silver;
        border-radius: 6px;
    }

    .lc-input-checkbox {
        width: 300px;
    }

    ul {
        list-style-type: none;
       }

    .filter-div {
        width: 100%;
        .filter-cell {
            min-width: none;
            display: flexbox;
        }
    }

    .filter-card {
        width: 100%;
        border: 1px dashed silver;
        margin: 4px;
    }

    .half-filter-card {
        border: 1px dashed silver;
    }

    .filter-submit {
        margin-top: 12px;
    }

}

.question-card-picklist {
    border: 1px dotted silver;
    border-radius: 6px;
    background-color: aliceblue;
    padding: 12px;
}
