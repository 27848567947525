@import '../../../css/helpers';

.action-list {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    line-height: 0;

    &--position-end {
        justify-content: flex-end;
    }
    &--position-start {
        justify-content: flex-start;
    }
    &--position-center {
        justify-content: center;
    }

    .lc-input-switch {
        margin: 0;

        &:last-child {
            padding: 0;
        }
    }

    .lc-input-checkbox {
        &,
        &.MuiFormControlLabel-root {
            margin: 0;
        }
    }
}
