@import '../../../../css/functions';
@import '../../../../css/variables';
@import '../../../../css/mixins';

.app-card {
    padding: 16px;
    box-shadow: var(--box-shadow-light);
    background-color: color('white');
    border-radius: 8px;
    transition: var(--transition-fast);
    transform: scale(1);
    overflow: visible;

    @media #{$mq-min-xs} {
        padding: 20px;
    }
    @media #{$mq-min-sm} {
        padding: 24px;
    }
    @media #{$mq-min-md} {
        padding: 32px;
    }

    & + & {
        margin-top: 8px;

        @media #{$mq-min-xs} {
            margin-top: 16px;
        }
        @media #{$mq-min-lg} {
            margin-top: 20px;
        }
        @media #{$mq-min-xl} {
            margin-top: 24px;
        }
    }

    &__grid {
        .app-card {
            margin: 0;
            grid-column: span 12;
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid color('lighter-gray');
        margin: -4px -16px 16px;
        padding: 0 16px 12px;

        @media #{$mq-min-xs} {
            margin: -4px -20px 20px;
            padding: 0 20px 16px;
        }
        @media #{$mq-min-sm} {
            margin: -4px -24px 24px;
            padding: 0 24px 20px;
        }
        @media #{$mq-min-md} {
            margin: -8px -32px 32px;
            padding: 0 32px 24px;
        }

        &__content {
            position: relative;
            top: 2px;
        }

        &__title.title {
            margin: 0;
        }

        &__description {
            font-size: 12px;
            font-weight: 400;
            color: color('bluegray');
            line-height: 16px;
        }

        &__icon {
            margin-right: 16px;
        }
    }

    &__divider {
        margin: 12px -16px;
        border-top-color: color('lighter-gray');

        @media #{$mq-min-xs} {
            margin: 16px -20px;
        }
        @media #{$mq-min-sm} {
            margin: 20px -24px;
        }
        @media #{$mq-min-md} {
            margin: 24px -32px;
        }
    }

    &__help {
        position: absolute;
        top: 2px;
        right: 2px;

        .lc-icon {
            transition: background 0.25s, color 0.25s;
            color: color('light-gray');
            height: 20px;
            width: 20px;
        }

        .lc-button:hover {
            .lc-icon {
                color: color('gray');
            }
        }
    }

    .dragging > & {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
}
