@import '../../css/functions';
@import '../../css/mixins';
@import '../../css/variables';

.filters {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 8px;

    .MuiFormControl-root.lc-input {
        display: block;

        .MuiInputBase-root {
            margin-bottom: 0;
            background-color: color('white');

            .MuiOutlinedInput-notchedOutline {
                border-color: color('white');
            }
        }
    }

    .lc-input-select .lc-input {
        margin-right: 0;
    }

    .lc-input {
        width: 140px;

        @media #{$mq-min-md} {
            width: 170px;
        }
    }

    .lc-input-wrapper {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }

    .lc-input-label-above {
        position: relative;

        .lc-input-label {
            position: absolute;
            top: -14px;
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &-daterange {
        &-inputs {
            min-width: 320px;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;

            .lc-input {
                max-width: calc(50vw - 28px);
            }
        }
    }

    &-button {
        color: color('black') !important;

        .lc-icon {
            color: color('dark-gray');
        }

        &,
        &:focus-visible {
            border-color: color('white') !important;
            background-color: color('white') !important;
        }

        &:hover,
        &:hover:active {
            border-color: color('lighter-gray') !important;
            background-color: color('lighter-gray') !important;
        }
    }

    &-icon-button {
        .lc-icon {
            color: color('dark-bluegray');
        }
    }

    &-button,
    &-icon-button {
        &.active {
            .lc-icon {
                color: color('primary_color');
            }
        }
    }

    &-loading {
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translate(-50%, -50%);
        margin-right: 4px;

        .MuiCircularProgress-colorPrimary {
            color: color('primary_color');
        }
    }
}
