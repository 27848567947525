@import '../../../../css/functions';
@import '../../../../css/mixins';
@import '../../../../css/variables';

.app-event-card {
    &-message-date {
        margin-top: 20px;
        margin-bottom: -15px;
        padding-left: 10px;
    }

    &-live-chat-comments {
        max-height: 440px;
        overflow-y: auto;
        margin-bottom: 0;

        code {
            color: color('black');
            white-space: pre-line;
            background: none;
        }
    }

    &-text-us-conversation {
        max-height: 440px;
        overflow-y: auto;
        margin: -16px;

        @media #{$mq-min-xs} {
            margin: -20px;
        }

        @media #{$mq-min-sm} {
            margin: -24px;
        }

        @media #{$mq-min-md} {
            margin: -32px;
        }
    }
}
