.question-editor-new {
    display: inline-flex;
    margin-top: 20px;

    .question-editor-new-select {
        width: 400px;
    }

    .add-question-submit {
        margin-left: 20px;
    }
}

.rotate-button {
    transform: rotate(180deg);
}

.question-editor {
    &-entry {
    background-color: #F5F6FA;
    border-radius: 12px;
    border: 1px dashed #0154fe;
    margin-bottom: 12px;
    padding-top: 8px;

        &-toolbar {
            width: 100%;
            display: inline-block;
            text-align: left;

            div {
                display: inline-block;
                position: relative;
            }

            &-checkbox {
                display: inline-block;
                position: relative;
                top: 7px;

            }

            &-trash-button {
                position: relative;
                float: right;
            }

            &-response-type-button {
                float: right;
                cursor: pointer;

                span {
                    background-color:lightgrey;
                    position: relative;
                    border: 1px solid black;
                    padding: 2px 4px;
                    margin: 4px;
                    top: 8px;
                    width: max-content;
                    border-radius: 4px;
                }

                span:hover {
                    border: 1px solid rgb(20, 20, 253);
                    background-color: whitesmoke;
                }

                .label-active {
                    background-color: #0154fe;
                    color: white;
                }

                .label-active:hover {
                    background-color: #0154fe;
                    color: white;
                }

                &-question-label {
                    font-weight: bolder;
                    cursor: pointer;

    
                }
            }


        }
        
        &-content {
            width: 100%;
            top: -16px;
            padding: 8px;

            &-textbox {
                background-color: white;
                text-align: center;
            }
        }

        &-select-section {
            width: 100%;

            &-toggle {
                color: #0154fe;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

