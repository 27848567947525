@import '../../../css/mixins';
@import '../../../css/variables';

.filter-drawer {
    z-index: $z-index-drawer;

    &-actions {
        @include auto-column-grid($gap: 16px);
    }
}
