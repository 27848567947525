.realync {
    p:not(:last-child) {
        margin-bottom: 16px;
    }

    &__connect-form,
    &__setup-form {
        .lc-input-wrapper {
            flex: 1;
        }

        .lc-input {
            margin-bottom: 16px;
        }

        &__property-id.lc-input {
            margin-bottom: 0;
        }

        .flex-row {
            width: 100%;
            gap: 16px;
        }

        .lc-button {
            min-width: 140px;
        }

        &__property_id {
            width: 100%;
        }
    }

    &__videos {
        $video-width: 240px;

        &-list {
            margin: 0 0 32px 0;

            &-item {
                position: relative;
                border: 1px solid #e2e2e2;
                border-radius: 6px;
                display: flex;
                align-items: center;
                margin: 0 0 16px 0;
                padding: 8px;

                label {
                    margin: 0;
                }

                .MuiFormControlLabel-label {
                    padding-left: $video-width + 16px;
                    z-index: 1;
                }

                &__thumbnail {
                    border-radius: 6px;
                    position: relative;
                    left: 48px;
                    height: 135px;
                    width: $video-width;
                    background: gray;
                    margin-right: 16px;
                    z-index: 0;
                }
            }

            &__pagination {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

.realync__videos-list-item-main-wrapper {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.4), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    .realync__videos-list-item-inner {
        width: 100%;
        height: 150px;

        .realync__videos-list-item__thumbnail {
            left: unset;
            width: 100%;
            height: 100%;
            margin: 0 !important;
            position: relative;


        }

        .play-button-link {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%);
        }
    }

    .card_body {
        padding: 10px;
        min-height: 60px;

        .card_title {
            font-size: 12px;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.custom_margin {
    margin: 10px 0;
}