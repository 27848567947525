// Note: We need to import variables directly here or else the `rgba()` function fails when used with the css variables.
// See here for reference: https://codeburst.io/magic-of-css-sass-variables-b07ae8c1b775#107a
@import './css-variables';

.btn {
    display: inline-flex;
    appearance: none;
    padding: 14px 20px;
    align-items: center;
    justify-content: center;
    font-family: 'clx-gilroy', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    outline: none !important;

    &:hover {
        cursor: pointer;
    }
    &:active {
        transform: scale(0.98);
    }
    &:disabled,
    &.disabled {
        transform: scale(1);
        opacity: 0.5;
        cursor: not-allowed;
    }

    .glyphicon,
    .caret {
        position: relative;
        top: -1px;
        line-height: 0;
        margin: 0 4px;
    }
}

.btn,
.btn-choose label,
.lc-button {
    --button-default-text: rgb(var(--lc-color-gray-darker));
    --button-default-bg: rgb(var(--lc-color-gray-lighter));
    --button-default-hover-bg: rgb(var(--lc-color-gray-light));
    --button-default-focus-ring: rgba(var(--lc-color-gray-lighter), var(--tw-ring-opacity, 1));

    --button-primary-text: rgb(var(--primary_button_text_color));
    --button-primary-bg: rgb(var(--primary_color));
    --button-primary-hover-bg: rgb(var(--primary_color_dark));
    --button-primary-focus-ring: rgba(var(--primary_color), var(--tw-ring-opacity, 1));

    --button-secondary-text: rgb(var(--secondary_button_text_color));
    --button-secondary-bg: rgb(var(--secondary_color));
    --button-secondary-hover-bg: rgb(var(--secondary_color_dark));
    --button-secondary-focus-ring: rgba(var(--secondary_color), var(--tw-ring-opacity, 1));

    --button-accent-text: rgb(var(--secondary_button_text_color));
    --button-accent-bg: rgb(var(--secondary_color));
    --button-accent-hover-bg: rgb(var(--secondary_color_dark));
    --button-accent-focus-ring: rgba(var(--secondary_color), var(--tw-ring-opacity, 1));

    --button-success-text: rgb(var(--lc-color-white));
    --button-success-bg: rgb(var(--lc-color-success));
    --button-success-hover-bg: rgb(var(--lc-color-success-dark));
    --button-success-focus-ring: rgba(var(--lc-color-success), var(--tw-ring-opacity, 1));

    --button-warning-text: rgb(var(--lc-color-white));
    --button-warning-bg: rgb(var(--lc-color-warning));
    --button-warning-hover-bg: rgb(var(--lc-color-warning-dark));
    --button-warning-focus-ring: rgba(var(--lc-color-warning), var(--tw-ring-opacity, 1));

    --button-danger-text: rgb(var(--lc-color-white));
    --button-danger-bg: rgb(var(--lc-color-danger));
    --button-danger-hover-bg: rgb(var(--lc-color-danger-dark));
    --button-danger-focus-ring: rgba(var(--lc-color-danger), var(--tw-ring-opacity, 1));

    &:focus {
        --tw-ring-opacity: 0.5;
        --tw-ring-color: transparent;
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent);
    }

    @each $button-style in ('primary', 'secondary', 'accent', 'default', 'success', 'warning', 'danger') {
        &-#{$button-style} {
            &,
            &:focus {
                color: var(--button-#{$button-style}-text) !important;
                background-color: var(--button-#{$button-style}-bg) !important;
                border-color: var(--button-#{$button-style}-bg) !important;
            }

            &:hover,
            &:active,
            &:active:hover,
            &:active:focus {
                color: var(--button-#{$button-style}-text) !important;
                background-color: var(--button-#{$button-style}-hover-bg) !important;
                border-color: var(--button-#{$button-style}-hover-bg) !important;
            }
            &:focus-visible {
                --tw-ring-color: var(--button-#{$button-style}-focus-ring) !important;
            }
        }
    }
}

.lc-button-link {
    color: color('blue');

    &:hover,
    &:active,
    &:focus {
        color: color('dark-blue');
    }
}

// Fixing upload buttons for LogoUploaded and ImageUpload components
.btn-choose {
    &,
    &:focus,
    label,
    label:focus {
        color: var(--button-primary-text) !important;
        background-color: var(--button-primary-bg) !important;
        border-color: var(--button-primary-bg) !important;
    }

    &:hover,
    &:active,
    &:active:hover,
    &:active:focus,
    label:hover,
    label:active,
    label:active:hover,
    label:active:focus {
        color: var(--button-primary-text) !important;
        background-color: var(--button-primary-hover-bg) !important;
        border-color: var(--button-primary-hover-bg) !important;
    }
    &:focus-visible,
    label:focus-visible {
        --tw-ring-color: var(--button-primary-focus-ring) !important;
    }
}

// TC-150 Fix for button within the alert panel, we can remove or update this when we update the alert component
.button.inPanel {
    display: flex;
    padding: 8px;
    min-height: 0;
    line-height: 12px;
    border: 1px solid white;
    margin-right: 40px;
    display: flex;
    align-items: center;

    .buttonText {
        margin: 0 4px;
        font-weight: 400;
    }
}

.button-disabled {
    cursor: not-allowed;
    opacity: 0.6;

    &:active {
        transform: scale(1);
    }
}
