@import '../../css/variables';
@import '../../css/mixins';

.companyOnboarding {
    min-height: 100vh;
    min-width: 100vw;
    background-color: #f5f6fa;

    @media (min-width: $breakpoint) {
        display: grid;
        grid-template-areas: 'header content';
        grid-template-columns: 420px auto;
        grid-template-rows: auto;
    }

    header {
        grid-area: header;
        background: white;
        padding: 16px 32px;

        @media (min-width: $breakpoint) {
            padding: 64px 64px 0;
        }

        h1 {
            font-weight: bold;
            font-size: 36px;
            line-height: 1.1;
        }

        p {
            font-size: 20px;
            line-height: 1.2em;
        }
    }

    &Subtitle {
        margin: -24px 0 16px 0;
    }

    &Content {
        margin: 0 auto;
        grid-area: content;
        padding: 32px;
        max-width: 600px;

        @media (min-width: $breakpoint) {
            display: grid;
            align-content: center;
            min-width: 600px;
        }

        h2 {
            margin: 64px 0 24px;
        }

        label {
            color: #455997;
            font-size: 14px;
            font-weight: 500;
        }

        label + div {
            width: 100%;
        }
    }

    &AgencyLogo {
        max-width: 292px;
        font-size: 24px;
        font-weight: bold;

        img {
            width: 100%;
            height: auto;
        }
    }

    &StepChecklist {
        display: none;
        margin-top: 48px;

        @media (min-width: $breakpoint) {
            display: block;
        }
    }

    &FormRow {
        @media (min-width: 480px) {
            @include auto-column-grid($gap: '16px');
        }
    }

    &FormActions {
        margin-top: 48px;
        display: flex;
        justify-content: flex-end;
    }

    &Illustration {
        display: none;

        @media (min-width: $breakpoint) {
            display: block;
            margin-top: 96px;
        }

        .companyOnboardingCompanyDetails & {
            bottom: 16px;
        }
    }

    &Actions {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button:last-child {
            width: 194px;
        }
    }

    &Credentials {
        header {
            grid-area: header;
            background: #016aff;
            color: white;

            @media (min-width: $breakpoint) {
                padding: 128px 64px 0;
            }
        }

        h2 {
            margin-top: 0;
        }
    }

    &CompanyDetails {
        .companyOnboardingContent {
            margin-bottom: 64px;
        }
    }

    &SMS,
    &Embed {
        &Illustration {
            display: block;
            margin: 0 auto;
        }

        h2 {
            margin-bottom: 8px;
        }

        p {
            line-height: 1.5;
        }
    }

    &Embed {
        h2 {
            margin-top: 0;
        }

        &Code {
            position: relative;
            margin-top: 32px;

            &Copy {
                width: 194px;
            }

            button {
                position: absolute;
                bottom: 0;
                right: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0;
            }

            code {
                padding: 32px 32px 64px;
                display: block;
                background: #252525;
                border-radius: 8px;
                font-size: 12px;
                line-height: 24px;
                color: white;
                word-break: break-all;
            }
        }
    }
}
