.notifications-holder {
    top: 0;
    right: 0;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    float: inherit;
    padding: 15px 0;
}

@media (max-width: 768px) {
    .notifications-holder {
        min-width: 425px;
    }
}

@media (max-width: 560px) {
    .notifications-holder {
        min-width: inherit;
    }
}

.new-class-btn {
    padding: 0;
    margin: 0 0 30px;
    display: flex;
    justify-content: flex-end;
    z-index: 9;

    .block-notification {
        display: flex;
        justify-content: space-between;
        overflow-y: scroll;
        border-radius: 0;
        color: #6d98c3;
        background-color: #fff;
    }

    a {
        font-weight: 500;
        text-decoration: underline;
    }
}
