@import '../../../../css/functions';

.position-section {
    .MuiFormGroup-root {
        margin-bottom: 24px;
        gap: 16px;
    }

    .MuiFormControl-root.lc-input {
        margin-bottom: 0;
    }

    .position-button.lc-icon-button {
        border: 1px solid color('light-gray');
        border-radius: var(--border-radius);

        &:hover {
            // Our normal lc-button hover color was too dark with the gray squares of the svg
            background-color: transparent;
            border-width: 2px;
        }

        &.active {
            border-color: color('primary_color');
            border-width: 2px;
        }
    }
}
