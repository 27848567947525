@import '../../../css/functions';

.lc-checkbox-list {
    gap: 8px;
    margin: 0 0 -8px;
    padding: 0;
    list-style: none;

    @each $column-count in 1, 2, 3 {
        &--columns-#{$column-count} {
            column-count: $column-count;
        }
    }

    &-item {
        display: block;

        .lc-input-checkbox {
            &.MuiFormControlLabel-root {
                display: block;
                margin: 0;
                border-radius: 4px;
                padding: 16px;
                cursor: pointer;
                margin-bottom: 8px;
                font-weight: 500;
                border: 1px solid color('light-gray');
                transition: var(--transition-fast);

                &:hover {
                    border-color: color('primary_color');
                }
            }

            .MuiIconButton-root {
                padding: 0;
                margin: 0;
                margin-right: 12px;
            }

            .MuiFormControlLabel-label {
                display: inline-block;
                position: relative;
                top: 1px;
            }
        }
    }
}
