@import '../../../css/functions';

.image-cropper {
    &-modal {
        & > div {
            max-width: 100% !important;
        }

        .MuiCard-root {
            max-width: 680px;
            max-height: calc(100vh - 80px);
            overflow: scroll;
            margin: 0 auto;
        }

        .MuiCardContent-root {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    &-title {
        padding: 16px;
        margin: 0 -16px;
        position: sticky;
        top: 0;
        background: white;
        width: 100%;
        box-sizing: content-box;
        z-index: 1;
    }

    &-cropper {
        display: flex;
        width: 648px;
        height: 608px;
        justify-content: center;
        align-items: center;
        padding: 24px;
        background-color: color('light-bluegray');
        border-radius: 4px;
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

        .ReactCrop {
            margin: 0 !important;

            &__image {
                max-height: 560px;
            }
        }
    }

    &-preview {
        display: flex;
        align-items: center;

        &-image {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            background-color: color('lighter-gray');
            border-radius: 4px;
            margin-right: 8px;

            img {
                width: 40px;
                height: 40px;
                object-fit: contain;
            }
        }
    }

    &-footer {
        display: flex;
        align-items: center;
        padding: 24px;
        position: sticky;
        bottom: 0;
        background: white;
        width: 100%;
        margin: 0 -24px;
        box-sizing: content-box;
    }

    &-actions {
        margin: -4px;

        .lc-button {
            margin: 4px;
        }
    }
}
