@import '../../../css/functions';

$snackbars-min-width: 280px;

.snackbars {
    // NOTE: This is so snackbars will display over the modal overlay
    z-index: 999999 !important;
    min-width: $snackbars-min-width !important;
}

.snackbar {
    .MuiSnackbarContent-root {
        padding: 8px 16px;
        min-width: $snackbars-min-width;
        color: #fff;
        font-size: inherit;
        line-height: inherit;
        box-shadow: var(--box-shadow);
        border-radius: 6px;
        background-color: color('black');

        &.snackbar-success {
            color: #fff;
            background-color: color('green');

            .snackbar-action-primary {
                color: color('green') !important;
            }
        }

        &.snackbar-warning {
            color: #fff;
            background-color: color('yellow');

            .snackbar-action-primary {
                color: color('yellow') !important;
            }
        }

        &.snackbar-error {
            color: #fff;
            background-color: color('red');

            .snackbar-action-primary {
                color: color('red') !important;
            }
        }

        &.snackbar-info {
            color: #fff;
            background-color: color('light-blue');

            .snackbar-action-primary {
                color: color('light-blue') !important;
            }
        }
    }

    .MuiSnackbarContent-message {
        padding: 10px 0 8px;
    }

    .lc-button.lc-button-unstyled {
        &.snackbar-action {
            padding: 8px 12px !important;
            margin-left: 4px !important;
            min-height: 40px !important;
            font-weight: 700 !important;
            border-radius: 6px !important;
            border: solid 1px rgba(255, 255, 255, 0.35) !important;

            &:hover:not([disabled]),
            &:focus:not([disabled]) {
                background-color: rgba(255, 255, 255, 0.16);
                border: solid 1px rgba(255, 255, 255, 0.35) !important;
            }

            &-primary {
                color: color('black') !important;
                background-color: #fff !important;

                &:hover:not([disabled]),
                &:focus:not([disabled]) {
                    color: color('black') !important;
                    background-color: rgba(255, 255, 255, 0.85) !important;
                }
            }
        }
    }
}
