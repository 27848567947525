.bengal {
    .szh-menu {
        border: solid 1px color('lighter-gray');
        border-radius: 6px;
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);

        &__item {
            min-width: 160px;
            padding: 12px 20px;

            &:hover,
            &:focus {
                background-color: color('lightest-gray');
            }

            &:active {
                color: color('black');
                background-color: color('lighter-gray');
            }

            .lc-icon {
                color: color('bluegray');
            }
        }
    }
}
