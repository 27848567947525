@import '../../../css/functions';

.lc-label {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 4px 8px;
    color: #fff;
    border-radius: 12px;
    margin: 2px 4px 2px 0;

    &--default {
        color: color('black');
        background-color: color('lighter-gray');
    }

    &--info {
        background-color: color('blue');
    }

    &--success {
        background-color: color('green');
    }

    &--warning {
        background-color: color('yellow');
    }

    &--error {
        background-color: color('red');
    }
}
