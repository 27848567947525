@import '../../css/variables';
@import '../../css/mixins';

.incomeCalculator {
    &RequiredQuestionOneText {
        grid-column: 1 / 3;
    }
}

.margin_top {
    margin-top: 10px;
}
