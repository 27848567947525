.alert-error,
.alert-danger {
    color: #a94442;
    background-color: #ffcccc;
    border-color: #a94442;
}

.new-col {
    .alert {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.bths-block-alert {
    max-width: 200px;
}

.alert {
    display: block;
    position: relative;
    padding: 15px;

    .close {
        right: 0 !important;
    }

    .proofing-alert {
        padding-top: 0;
        padding-bottom: 0;
        div {
            clear: both;
        }
    }

    .proofing-alert-text {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 20px;

        span {
            float: left;
        }

        button,
        a {
            display: flex;
            align-items: center;
            float: right;
            margin-left: 10px;
            margin-top: 0;
            margin-bottom: 0;
        }

        button {
            @media (max-width: 950px) {
                margin-bottom: 10px;
            }
        }
    }
}

.alert-text-col {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.row-hold__alerts-list {
    .alert-dismissible {
        padding-right: 15px;
        .close {
            right: 0;
        }
    }
}

.alerts-list .alert:last-child {
    margin: 0;
}

/* //  Notifiations */

.row-hold__alerts-list .alerts-list .alert > .view {
    color: #686868;
}

.alerts-list__in {
    overflow-y: scroll;
    max-height: 50vh;
}

.row-hold__alerts-list {
    .alerts-list {
        border-top: 1px solid #b6b6b6;
        border-radius: 0;
    }

    .alert {
        margin-left: 0;
        margin-right: 0;
        border-radius: 0;
        background-color: inherit;
        border: 0;
        padding: 0 5px;
        margin: 0 0 5px;
        color: #6d98c3;
    }

    .alert:nth-child(odd) {
        background-color: #e0e0e0;
        position: relative;
    }

    .alert-success > div,
    .alert-warning > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 2;
        padding: 2px 0;
    }

    .alert-success > div div:nth-child(1),
    .alert-warning > div div:nth-child(1) {
        width: 190px;
        padding-right: 10px;
    }
}

@media (max-width: 900px) {
    .row-hold__alerts-list .alert-success > div div:nth-child(1),
    .row-hold__alerts-list .alert-warning > div div:nth-child(1) {
        width: 140px;
    }
}

.row-hold__alerts-list .alert-success > div div:nth-child(2),
.row-hold__alerts-list .alert-warning > div div:nth-child(2) {
    width: 55%;
    padding-right: 10px;
    min-width: 220px;
}

@media (max-width: 900px) {
    .row-hold__alerts-list .alert-success > div div:nth-child(2),
    .row-hold__alerts-list .alert-warning > div div:nth-child(2) {
        width: 55%;
    }
}

@media (max-width: 768px) {
    .row-hold__alerts-list {
        .alert-success > div div:nth-child(2),
        .alert-warning > div div:nth-child(2) {
            width: 30%;
        }
    }
}

.row-hold__alerts-list {
    .alert-success > div div:nth-child(3),
    .alert-warning > div div:nth-child(3) {
        width: 120px;
        text-decoration: underline;
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
    }

    .alert-success > div div:nth-child(4),
    .alert-warning > div div:nth-child(4) {
        min-width: 70px;
        text-decoration: underline;
        padding-right: 0;
        color: #5c5c5c;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 1282px) {
    .row-hold__alerts-list .alert-success > div div:nth-child(4),
    .row-hold__alerts-list .alert-warning > div div:nth-child(4) {
        width: 70px;
    }
}

.row-hold__alerts-list {
    .alert-success .view .click-span,
    .alert-warning .view .click-span {
        margin-left: 15px;
    }

    .alert-success .view,
    .alert-warning .view {
        text-decoration: none;
        font-weight: normal;
    }
}

.alerts-list .block-notification .list-group__holder .container .error {
    padding-right: 15px;
    padding-left: 15px;
}

.alert {
    margin-left: -15px;
    margin-right: -15px;
    .close {
        outline: none;
        font-size: 44px;
        top: -14px;
        font-weight: 300;
        span {
            display: inline-block;
        }
    }
}

.holder-padding .alert {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
}

.leadNurtureMain .alert {
    margin: 10px;
}

.alert-chat-holder {
    margin-right: -15px;
    margin-left: -15px;
    .none-margin-inden {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.login-alert .close {
    position: absolute;
    top: 1px;
    right: 10px;
}

.row-hold__alerts-list .col-block {
    color: #8f8f8f;
    letter-spacing: 1px;
}

.row-hold__alerts-list .col-block > div {
    margin-top: 2px;
    padding: 10px 0 0 0;
    margin-bottom: 15px;
}

.alert-notification-group {
    margin-left: 15px;
    margin-right: 15px;
    .close {
        padding-top: 12px;
    }
    .alert {
        display: block;
        padding: 15px;
    }
}

.sms-chat-proofing {
    float: inherit;
}

.css-alert-files {
    display: block;
    padding: 15px;
    .close {
        right: 0;
    }
}
