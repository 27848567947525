@import '../../../../css/helpers';
@import '../../../../css/functions';
@import '../../../../css/variables';

.app-list {
    @extend .unstyled-list;

    h3 {
        margin: 0;

        button {
            padding: 0;
        }
    }

    &-header {
        color: color('bluegray');
        padding: 0 17px 16px;
    }

    &-item {
        display: flex;
        gap: 12px;
        padding: 20px;
        border: solid 1px color('lighter-gray');
        border-radius: 6px;
        transition: all 0.15s ease-in-out;

        @media #{$mq-min-lg} {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 16px;
        }

        &:hover {
            border-color: color('bluegray');
        }

        &-icon {
            min-width: 40px;
            width: 40px;
            height: 40px;

            @media #{$mq-min-lg} {
                margin-bottom: 0;
            }
        }

        &-content {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 12px;
            flex: 1 1 auto;

            @media #{$mq-min-lg} {
                flex-basis: 35%;
            }
        }

        &-details {
            width: 100%;
            padding-top: 16px;
            flex: 1 1 auto;

            @media #{$mq-min-lg} {
                width: auto;
                padding-top: 0;
                margin: 0 auto;
                flex-basis: 35%;
            }
        }

        &-handle {
            &.dnd-action-handle {
                position: relative;
                top: unset;
                left: unset;
            }
        }

        &-actions {
            flex-shrink: 0;
            border-top: solid 1px color('lighter-gray');
            margin: 20px -20px -8px;
            padding: 12px 16px 0;

            @media #{$mq-max-lg} {
                .action-list {
                    width: 100%;
                    justify-content: flex-start !important;
                }
            }

            @media #{$mq-min-lg} {
                width: auto;
                margin: 0;
                padding: 0;
                border-top: none;
            }

            .lc-input-switch {
                margin-right: 8px;
            }
        }

        &-title {
            font-size: 16px;
            margin: 0;
            white-space: normal;

            .lc-button {
                font-size: inherit;
            }
        }

        &-description {
            font-size: 14px;
            font-weight: 400;
            color: color('dark-bluegray');
            line-height: 20px;
        }
    }

    &-item+&-item {
        margin-top: 12px;
    }

    &-empty {
        display: grid;
        place-items: center;
        padding: 48px 0;
        max-width: 520px;
        margin: 0 auto;
        text-align: center;

        &-title.title {
            margin: 32px 0 0;
        }

        &-description {
            font-size: 16px;
            line-height: 1.25;
            margin: 20px 0 0;
            max-width: 440px;
        }

        &-action {
            margin: 24px 0 0;

            .lc-input-switch {
                padding: 0;
            }
        }
    }

    &-loading {
        padding: 96px 0;
    }
}
