.hasSuffix {
    position: relative;
}

.suffix {
    position: absolute;
    top: 34px;
    right: 16px;
    color: gray;
    font-size: 18px;
}
