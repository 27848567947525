@import '../variables';
@import '../functions';

// Fix for when styles get added to tabs when a snackbar appears
.MuiAppBar-root.MuiPaper-elevation4 {
    box-shadow: none !important;
    background-color: transparent !important;
}

.MuiTabs-root .MuiTab-root {
    padding: 16px 12px;
    font-size: 1em;
    background-color: color('white');
    border-bottom: 2px solid rgba(var(--lc-color-primary), 0.8);
    font-weight: 700;

    &.Mui-selected {
        color: color('primary_color');
    }

    &:first-child {
        border-top-left-radius: 8px;
    }

    &:last-child {
        border-top-right-radius: 8px;
    }
}
