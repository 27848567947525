@import './variables';
@import './functions';

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-thin.woff2') format('woff2'), url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-thin.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-ultralight.woff2') format('woff2'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-ultralight.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-ultralight.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-light.woff2') format('woff2'), url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-light.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-regular.woff2') format('woff2'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-regular.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-medium.woff2') format('woff2'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-medium.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-semibold.woff2') format('woff2'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-semibold.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-semibold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-bold.woff2') format('woff2'), url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-bold.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-extrabold.woff2') format('woff2'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-extrabold.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-extrabold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'clx-gilroy';
    src: url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-heavy.woff2') format('woff2'), url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-heavy.woff') format('woff'),
        url('https://tcc.sfo2.cdn.digitaloceanspaces.com/fonts/gilroy/gilroy-heavy.ttf') format('truetype');
    font-weight: 900;
}

body {
    font-weight: 500;
    font-family: var(--font-family-sans) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MuiTypography-body1 {
    font-size: 14px !important;
}

// enforcing consistent input error text size
.MuiFormControl-root .MuiFormHelperText-root,
.lc-stripe-card-input-error {
    font-weight: 500;
    font-size: 14px;
}

// Fixes for bootstrap heading styles
.page-header,
.chartWrapper {
    h1 {
        @extend .h1;
    }
}

.panel-title h4 {
    @extend .h4;
}

.panel-body {
    h2 {
        @extend .h2;
    }

    h3 {
        @extend .h3;
    }

    h4 {
        @extend .h4;
    }

    hr {
        @extend .hr;
    }

    p {
        margin: 0 0 10px;
    }
}

dl {
    margin-bottom: 0 !important;
    gap: 16px;

    @media #{$mq-min-md} {
        gap: 24px;
    }

    &:empty {
        display: none;
    }

    dt {
        &+dd {
            margin-top: 8px;

            @media #{$mq-min-sm} {
                margin-top: 12px;
            }
        }
    }

    dd {
        margin-bottom: 0;
        padding: 8px 12px;
        background-color: color('lightest-gray');
        border-radius: 6px;
        word-wrap: break-word;

        @media #{$mq-min-sm} {
            padding: 12px 16px;
        }

        &+dt {
            margin-top: 16px;

            @media #{$mq-min-sm} {
                margin-top: 20px;
            }
        }
    }
}

p {
    margin-bottom: 24px;
}