@import '../../../../css/functions';

.styling-section {
    .lc-panel {
        padding: 24px;
    }

    .expanded-checkbox.lc-input-checkbox {
        margin-bottom: 16px; // Default margin was not enough to keep the correct spacing
    }

    &-contrast-error {
        color: color('lc-color-danger');
        font-size: 0.8em;
        line-height: 1.25;
        font-family: inherit;
        margin: -16px 0 24px;
        letter-spacing: 0;
    }
}
