.company-onboarding {
    .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
        border-color: white;
    }

    input {
        background-color: white;
        border-radius: 4px;
    }
}
