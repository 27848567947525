@import '../../../css/variables';

.MuiTooltip {
    &-tooltip {
        font-size: 14px !important;
    }

    &-tooltipPlacementTop,
    &-tooltipPlacementBottom {
        margin: 8px 0 !important;
    }

    &-tooltipPlacementLeft,
    &-tooltipPlacementRight {
        margin: 0 !important;
    }
}

.tooltip-wrapper {
    line-height: 0;
}
