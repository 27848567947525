@import '../../../css/functions';
@import '../../../css/mixins';
@import '../../../css/variables';

.stat-card {
    --stat-card-icon-blue: var(--blue);
    --stat-card-icon-blue-bg: var(--lightest-blue);
    --stat-card-icon-green: var(--green);
    --stat-card-icon-green-bg: var(--green);

    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;

    &.app-card {
        margin: 0;

        @media #{$mq-min-xs} {
            grid-column: span 6;
        }
        @media #{$mq-min-lg} {
            grid-column: span 4;
        }
        @media #{$mq-min-xl} {
            grid-column: span 3;
        }
    }

    &__icon {
        display: inline-flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        margin-right: 16px;
        transition: var(--transition-fast);

        @media #{$mq-min-sm} {
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }
        @media #{$mq-min-md} {
            width: 48px;
            height: 48px;
            margin-right: 20px;
        }
        @media #{$mq-min-lg} {
            width: 60px;
            height: 60px;
            margin-right: 24px;
        }
    }

    &__value {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        @media #{$mq-min-md} {
            font-size: 20px;
        }
    }

    &__label {
        font-size: 12px;
        line-height: 1.3;
        color: color('dark-bluegray');

        @media #{$mq-min-md} {
            font-size: 14px;
        }
    }

    @each $stat-color in ('blue', 'green') {
        &--#{$stat-color} &__icon {
            color: color('stat-card-icon-#{$stat-color}');
            background: color('stat-card-icon-#{$stat-color}-bg', 0.1);
        }

        // TODO: Add hover states back in when/if cards are clickable
        // &-#{$stat-color}:hover &-icon {
        //     color: color('white');
        //     background: color('stat-card-icon-#{$stat-color}');
        // }
    }

    // TODO: Add hover states back in when/if cards are clickable
    // &:hover {
    //     z-index: 2;
    //     box-shadow: 0 20px 30px -10px rgba(0, 0, 0, 0.15);
    //     transform: scale(1.025);
    // }
}
