@import '/../../../css/functions';

.advanced-settings-modal {
    .MuiCardContent-root {
        display: grid;
        grid-template-rows: 75px 1fr 80px;
        grid-template-areas:
            'header'
            'content'
            'footer';
        padding: 0 !important;
    }

    .lc-modal-header {
        margin: 0;
        background-color: color('white');
        grid-area: header;
        height: 75px;
    }

    .advanced-settings-modal-content {
        grid-area: content;
        overflow: auto;
    }

    .lc-modal-actions {
        background-color: color('white');
        grid-area: footer;
        height: 80px;
        margin: 0 !important;
    }
}

// TODO: Consider creating a separate `EmbedCode` component.
.embed-code {
    position: relative;

    button {
        position: absolute;
        bottom: 0;
        right: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }

    &-content.lc-input {
        word-break: break-all;
        margin-bottom: 24px;

        textarea.MuiInputBase-input {
            color: color('white');
            font-size: 16px;
            line-height: 24px;
            word-break: break-all;

            &:not(:focus) ~ .MuiOutlinedInput-notchedOutline {
                border-color: color('black');
            }
        }
    }

    &-readonly &-content,
    &-content.lc-input {
        font-size: 16px;
        line-height: 24px;
        border-radius: 6px;
        color: color('white');
        background-color: color('black');
    }

    &-readonly &-content {
        padding: 16px;
        word-break: break-all;
        margin-bottom: 24px;
    }

    &-has-button#{&}-readonly &-content,
    &-has-button &-content.lc-input textarea.MuiInputBase-input {
        padding-bottom: 64px;
    }
}
