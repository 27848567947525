@import '../../css/functions';
@import '../../css/variables';
@import '../../css/mixins';

.application-settings {
    &-content {
        padding-bottom: 64px;
    }
}

.advanced-settings {
    @media #{$mq-max-sm} {
        padding-right: 4px;

        .lc-button-content {
            display: none;
        }
    }
}
