.rule-editor-new {
    display: inline-flex;
    margin-top: 20px;

    .rule-editor-new-select {
        width: 400px;
    }

    .add-rule-submit {
        margin-left: 20px;
    }
}

.rule-wrapper {
    border: whitesmoke 4px solid;
    display: inline-flex;
    width: 100%;
    padding: 20px;
    padding-top: 40px;
    border-radius: 10px;
}

.rule-conditions {
    display: inline-flexbox;
    width: 60%;
    border-right: whitesmoke 4px solid;
}

.rule-condition-row-wrapper {
    text-align: center;
    margin-top: 8px;
}

.rule-condition-and {
    font-weight: bolder;
    color: green;
    position: relative;
    top: -10px;
}

.rotate-button {
    transform: rotate(180deg);
}

.rule-commands {
    border: whitesmoke 2px solid;
    background-color: whitesmoke;
    border-radius: 12px;
    display: inline-block;
    text-align: center;
    position: relative;
    top: -40px;
    left: 400px;
    padding: 6px 40px;
    margin-left: 20px;
    div {
        display: inline-block;
        position: relative;
        top: 8px;
    }
    .trash-button {
        position: relative;
        top: 0px;
    }
}

.rule-condition-row {
    display: inline-flex;
    width: 100%;
}

.rule-actions {
    display: inline-flexbox;
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 12px;
}

span.rule-layout {
    padding: 14px;
    font-weight: bolder;
}

.rule-subject {
    width: 40%;
}

.rule-action-data {
    width: 300px;
}

.rule-predicate-picklist {
    width: 200px;
}

.rule-editor-delete {
    margin-right: 20px;
    margin-top: 6px;
}
.rule-action-active {
    margin-left: 12px;
}
