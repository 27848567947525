.react-bs-table .react-bs-container-header .sort-column,
.s-alert-close,
td.react-bs-table-expand-cell,
th.react-bs-table-expand-cell > div {
    cursor: pointer;
}
.react-bs-table-container .react-bs-table-search-form {
    margin-bottom: 0;
}
.react-bs-table-bordered {
    border: 1px solid #ddd;
    border-radius: 5px;
}
.react-bs-table table {
    margin-bottom: 0;
    table-layout: fixed;
}
.react-bs-table table td,
.react-bs-table table th {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.react-bs-table-pagination {
    margin-top: 10px;
}
.react-bs-table-tool-bar {
    margin-bottom: 5px;
}
.react-bs-container-footer,
.react-bs-container-header {
    overflow: hidden;
    width: 100%;
}
.react-bs-container-body {
    overflow: auto;
    width: 100%;
}
.react-bootstrap-table-page-btns-ul {
    float: right;
    margin-top: 0;
}
.react-bs-table .table-bordered {
    border: 0;
    outline: 0 !important;
}
.react-bs-table .table-bordered > thead > tr > td,
.react-bs-table .table-bordered > thead > tr > th {
    border-bottom-width: 2px;
}
.react-bs-table .table-bordered > tbody > tr > td {
    outline: 0 !important;
}
.react-bs-table .table-bordered > tbody > tr > td.default-focus-cell {
    outline: #6495ed solid 3px !important;
    outline-offset: -1px;
}
.react-bs-table .table-bordered > tfoot > tr > td,
.react-bs-table .table-bordered > tfoot > tr > th {
    border-top-width: 2px;
    border-bottom-width: 0;
}
.react-bs-table .table-bordered > tbody > tr > td:first-child,
.react-bs-table .table-bordered > tbody > tr > th:first-child,
.react-bs-table .table-bordered > tfoot > tr > td:first-child,
.react-bs-table .table-bordered > tfoot > tr > th:first-child,
.react-bs-table .table-bordered > thead > tr > td:first-child,
.react-bs-table .table-bordered > thead > tr > th:first-child {
    border-left-width: 0;
}
.react-bs-table .table-bordered > tbody > tr > td:last-child,
.react-bs-table .table-bordered > tbody > tr > th:last-child,
.react-bs-table .table-bordered > tfoot > tr > td:last-child,
.react-bs-table .table-bordered > tfoot > tr > th:last-child,
.react-bs-table .table-bordered > thead > tr > td:last-child,
.react-bs-table .table-bordered > thead > tr > th:last-child {
    border-right-width: 0;
}
.react-bs-table .table-bordered > thead > tr:first-child > td,
.react-bs-table .table-bordered > thead > tr:first-child > th {
    border-top-width: 0;
}
.react-bs-table .table-bordered > tfoot > tr:last-child > td,
.react-bs-table .table-bordered > tfoot > tr:last-child > th {
    border-bottom-width: 0;
}
.react-bs-table .react-bs-container-footer > table > thead > tr > th,
.react-bs-table .react-bs-container-header > table > thead > tr > th {
    vertical-align: middle;
}
.react-bs-table .react-bs-container-footer > table > thead > tr > th .filter,
.react-bs-table .react-bs-container-header > table > thead > tr > th .filter {
    font-weight: 400;
}
.react-bs-table .react-bs-container-header > table > thead > tr > th .filter::-webkit-input-placeholder,
.react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter-input::-webkit-input-placeholder,
.react-bs-table .react-bs-container-header > table > thead > tr > th .select-filter option[value=''],
.react-bs-table .react-bs-container-header > table > thead > tr > th .select-filter.placeholder-selected {
    color: #d3d3d3;
    font-style: italic;
}
.react-bs-table .react-bs-container-footer > table > thead > tr > th .filter::-webkit-input-placeholder,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .number-filter-input::-webkit-input-placeholder,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .select-filter option[value=''],
.react-bs-table .react-bs-container-footer > table > thead > tr > th .select-filter.placeholder-selected {
    color: #d3d3d3;
    font-style: italic;
}
.react-bs-table
    .react-bs-container-footer
    > table
    > thead
    > tr
    > th
    .select-filter.placeholder-selected
    option:not([value='']),
.react-bs-table
    .react-bs-container-header
    > table
    > thead
    > tr
    > th
    .select-filter.placeholder-selected
    option:not([value='']) {
    color: initial;
    font-style: initial;
}
.react-bs-table .react-bs-container-footer > table > thead > tr > th .date-filter,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .number-filter,
.react-bs-table .react-bs-container-header > table > thead > tr > th .date-filter,
.react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter {
    display: flex;
}
.react-bs-table .react-bs-container-footer > table > thead > tr > th .date-filter-input,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .number-filter-input,
.react-bs-table .react-bs-container-header > table > thead > tr > th .date-filter-input,
.react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter-input {
    margin-left: 5px;
    float: left;
    width: calc(100% - 67px - 5px);
}
.react-bs-table .react-bs-container-footer > table > thead > tr > th .date-filter-comparator,
.react-bs-table .react-bs-container-footer > table > thead > tr > th .number-filter-comparator,
.react-bs-table .react-bs-container-header > table > thead > tr > th .date-filter-comparator,
.react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter-comparator {
    width: 67px;
    float: left;
}
.react-bs-container .textarea-save-btn {
    position: absolute;
    z-index: 100;
    right: 0;
    top: -21px;
}
.react-bs-table-no-data {
    text-align: center;
}
.animated {
    animation-fill-mode: both;
}
.animated.bounceIn,
.animated.bounceOut {
    animation-duration: 0.75s;
}
.animated.shake {
    animation-duration: 0.3s;
}
@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }
    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }
    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}
.shake {
    animation-name: shake;
}
@keyframes bounceIn {
    20%,
    40%,
    60%,
    80%,
    from,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
.bounceIn {
    animation-name: bounceIn;
}
@keyframes bounceOut {
    20% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50%,
    55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}
.bounceOut {
    animation-name: bounceOut;
}
.s-alert-box,
.s-alert-box * {
    box-sizing: border-box;
}
.s-alert-box {
    position: fixed;
    background: rgba(42, 45, 50, 0.85);
    padding: 22px;
    line-height: 1.4;
    z-index: 1000;
    pointer-events: none;
    color: rgba(250, 251, 255, 0.95);
    font-size: 100%;
    font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
    max-width: 300px;
    -webkit-transition: top 0.4s, bottom 0.4s;
    transition: top 0.4s, bottom 0.4s;
}
.s-alert-box.s-alert-show,
.s-alert-box.s-alert-visible {
    pointer-events: auto;
}
.s-alert-box a {
    color: inherit;
    opacity: 0.7;
    font-weight: 700;
}
.s-alert-box a:focus,
.s-alert-box a:hover {
    opacity: 1;
}
.s-alert-box p {
    margin: 0;
}
.s-alert-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4px;
    top: 4px;
    overflow: hidden;
    text-indent: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.s-alert-close:focus,
.s-alert-close:hover {
    outline: 0;
}
.s-alert-close::after,
.s-alert-close::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 60%;
    top: 50%;
    left: 50%;
    background: #fff;
}
.s-alert-close:hover::after,
.s-alert-close:hover::before {
    background: #fff;
}
.s-alert-close::before {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}
.s-alert-close::after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}
.s-alert-bottom-left {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 30px;
}
.s-alert-top-left {
    top: 30px;
    right: auto;
    bottom: auto;
    left: 30px;
}
.s-alert-top-right {
    top: 30px;
    right: 30px;
    bottom: auto;
    left: auto;
}
.s-alert-bottom-right {
    top: auto;
    right: 30px;
    bottom: 30px;
    left: auto;
}
.s-alert-bottom,
.s-alert-top {
    width: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
}
.s-alert-bottom {
    bottom: 0;
    top: auto;
}
.s-alert-top {
    top: 0;
    bottom: auto;
}
.s-alert-info {
    background: #00a2d3;
    color: #fff;
}
.s-alert-success {
    background: #27ae60;
    color: #fff;
}
.s-alert-warning {
    background: #f1c40f;
    color: #fff;
}
.s-alert-error {
    background: #e74c3c;
    color: #fff;
}
[class*=' s-alert-effect-'].s-alert-hide,
[class^='s-alert-effect-'].s-alert-hide {
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}
.s-alert-box-height {
    visibility: hidden;
    position: fixed;
}
.s-alert-effect-scale a,
.s-alert-effect-scale a:focus,
.s-alert-effect-scale a:hover {
    color: #fff;
}
.s-alert-effect-scale .s-alert-close::after,
.s-alert-effect-scale .s-alert-close::before,
.s-alert-effect-scale .s-alert-close:hover::after,
.s-alert-effect-scale .s-alert-close:hover::before {
    background: #fff;
}
.s-alert-effect-scale.s-alert-hide,
.s-alert-effect-scale.s-alert-show {
    -webkit-animation-name: animScale;
    animation-name: animScale;
    -webkit-animation-duration: 0.25s;
    animation-duration: 0.25s;
}
@-webkit-keyframes animScale {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
}
@keyframes animScale {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
        transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
}
