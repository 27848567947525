@import '../../../css/helpers';

.title,
.header {
    @extend .app-title;
    margin-top: 0;
}

.header {
    font-size: 16px;
}

.h3 {
    margin: 16px 0;
    font-size: 16px;
    font-weight: 700;
}
