@import '../../../../css/variables';

.GridContainer {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    padding: 0;

    @media #{$mq-min-sm} {
        grid-template-columns: repeat(var(--col-count), 1fr);
    }

    @media #{$mq-min-md} {
        grid-template-columns: 1fr;
    }

    @media screen and (min-width: 1199px) {
        grid-template-columns: repeat(var(--col-count), 1fr);
    }
}
