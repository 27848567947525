// Sizes
$top-bar-height: 72px;
$side-nav-width: 320px;

// Z-indexes
$z-index-sidebar: 1200 !important;
$z-index-drawer: 1201 !important;
$z-index-header: 1202 !important;

// Breakpoints

$breakpoint: 1100px;

$breakpoint-xs: 480px;
$breakpoint-sm: 790px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1440px;

$mq-min-xs: 'screen and (min-width: #{$breakpoint-xs})';
$mq-min-sm: 'screen and (min-width: #{$breakpoint-sm})';
$mq-min-md: 'screen and (min-width: #{$breakpoint-md})';
$mq-min-lg: 'screen and (min-width: #{$breakpoint-lg})';
$mq-min-xl: 'screen and (min-width: #{$breakpoint-xl})';

$mq-max-xs: 'screen and (max-width: #{$breakpoint-xs - 1px})';
$mq-max-sm: 'screen and (max-width: #{$breakpoint-sm - 1px})';
$mq-max-md: 'screen and (max-width: #{$breakpoint-md - 1px})';
$mq-max-lg: 'screen and (max-width: #{$breakpoint-lg - 1px})';
$mq-max-xl: 'screen and (max-width: #{$breakpoint-xl - 1px})';

$media-queries: (
    'xs': $mq-min-xs,
    'sm': $mq-min-sm,
    'md': $mq-min-md,
    'lg': $mq-min-lg,
    'xl': $mq-min-xl,
    'pr': 'print'
);
