@import '../../../../css/variables';

.app-content {
    padding: 24px;

    @media #{$mq-min-lg} {
        padding: 32px;
    }

    &.loading {
        min-height: 500px;
    }
}
