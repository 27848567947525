#OnboardingIllustration {
    #line1 {
        animation: lineTaller 7s infinite;
    }

    #line2 {
        animation: lineTaller 7s infinite;
        animation-delay: 2s;
    }

    #line3 {
        animation: lineShorter 7s infinite;
        animation-delay: 4s;
    }

    @keyframes lineTaller {
        0% {
            transform: scaleY(1);
        }
        50% {
            transform: scaleY(0.9);
        }
        100% {
            transform: scaleY(1);
        }
    }

    @keyframes lineShorter {
        0% {
            transform: scaleY(1);
        }
        50% {
            transform: scaleY(0.95);
        }
        100% {
            transform: scaleY(1);
        }
    }

    #loading1 {
        animation: colorFade 6s infinite;
    }

    #loading2 {
        animation: colorFade 6s infinite;
        animation-delay: 2s;
    }

    #loading3 {
        animation: colorFade 6s infinite;
        animation-delay: 4s;
    }

    @keyframes colorFade {
        0% {
            fill: #1487e3;
        }
        50% {
            fill: #4aafff;
        }
        100% {
            fill: #1487e3;
        }
    }
}
