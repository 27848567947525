@import '../../../css/functions';
@import '../../../css/helpers';

.agency-email-dns-settings {
    &-table {
        .lc-table {
            border-collapse: initial;
        }

        tbody tr {
            th,
            td {
                cursor: pointer !important;
                border-top: solid 2px transparent;
                border-bottom: solid 2px transparent;
                transition: all 0.15s ease-in-out;

                &:first-child {
                    border-left: solid 2px transparent;
                }
                &:last-child {
                    border-right: solid 2px transparent;
                }
            }

            &:hover {
                th,
                td {
                    border-color: color('primary_color');
                }
            }
        }

        &-value {
            @extend .truncate;
            max-width: 240px;
        }

        &-empty-state {
            @at-root tbody tr td#{&} {
                cursor: initial !important;
                text-align: center !important;
                border-color: transparent !important;
            }
        }
    }

    &-modal {
        &.lc-modal {
            .MuiCard-root {
                width: 520px;
                max-width: 100%;
                min-width: 0;
            }
        }
    }
}
