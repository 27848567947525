@import '../../css/variables';
@import '../../css/mixins';

.concession-manager {
    &-duration-row {
        @include column-grid($gap: '16px');
    }

    &-paths {
        &-row {
            display: grid;
            grid-template-columns: 1fr 36px 36px;
            column-gap: 8px;
            margin-right: -8px;

            & > :first-child {
                margin-right: 8px;
            }
        }
    }

    &-offer-ongoing-checkbox {
        margin-top: -20px;
    }

    &-edit-email-button {
        margin-bottom: 24px;
    }

    &-section-header {
        &-checkbox {
            margin: 0 !important;
        }
    }
}
