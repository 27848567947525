@import './variables';

@mixin responsive-utility($property, $values, $variants: ()) {
    @each $key, $value in $values {
        .clx-#{$property}-#{$key} {
            #{$property}: #{$value} !important;
        }

        @if $variants {
            @each $variant in $variants {
                .clx-#{$property}-#{$variant}-#{$key} {
                    #{$property}-#{$variant}: #{$value} !important;
                }
            }
        }
    }

    @each $size, $media-query in $media-queries {
        @each $key, $value in $values {
            .#{$size}\:clx-#{$property}-#{$key} {
                @media #{$media-query} {
                    #{$property}: #{$value} !important;
                }
            }

            @if $variants {
                @each $variant in $variants {
                    .#{$size}\:clx-#{$property}-#{$variant}-#{$key} {
                        #{$property}-#{$variant}: #{$value} !important;
                    }
                }
            }
        }
    }
}

$spacing: (
    0: '0',
    1: '1px',
    2: '2px',
    4: '4px',
    8: '8px',
    12: '12px',
    16: '16px',
    20: '20px',
    24: '24px',
    32: '32px',
    36: '36px',
    40: '40px',
    48: '48px'
);

$font-sizes: (
    12: '12px',
    16: '16px',
    20: '20px',
    24: '24px',
    32: '32px',
    36: '36px',
    40: '40px',
    48: '48px'
);

$display: (
    none: 'none',
    inline: 'inline',
    block: 'block',
    inline-block: 'inline-block',
    flex: 'flex',
    inline-flex: 'inline-flex',
    grid: 'grid',
    inline-grid: 'inline-grid'
);

$text-align: (
    left: 'left',
    right: 'right',
    center: 'center',
    justify: 'justify'
);

$font-weight: (
    100: '100',
    200: '200',
    300: '300',
    400: '400',
    500: '500',
    600: '600',
    700: '700',
    800: '800',
    900: '900'
);

@include responsive-utility('display', $display);
@include responsive-utility('margin', $spacing, ('top', 'left', 'right', 'bottom'));
@include responsive-utility('font-size', $font-sizes);
@include responsive-utility('text-align', $text-align);
@include responsive-utility('font-weight', $font-weight);
