.app-order-list-container {
    margin: 0;
    padding: 0;
    min-width: 100%;

    li {
        margin: 8px 0;
    }
    .app-list-item-checkbox {
        margin: 0 8px;
    }
}
