@import url('https://fonts.googleapis.com/css?family=Lato:400,900');

.main-container.widget-settings {
    overflow: visible;
}

.clx-widget-menu-wrapper {
    right: 20px;
    top: auto;
    left: auto;
    bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.preview-widget-wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    padding-bottom: 14px;
    margin-top: -10px;
    padding-top: 10px;
}

@media (max-width: 769px) {
    .preview-widget-wrapper {
        position: static;
        padding-bottom: 0;
    }
}

.preview-widget-container {
    position: relative;
    height: 480px;
    border: 1px solid color('secondary_color');
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    overflow: hidden;
    margin-bottom: 5px;
}

.widget-preview-header {
    padding: 20px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #fff;
    background-color: color('secondary_color');
    border-color: color('secondary_color');
    color: color('secondary_button_text_color');
    font-weight: 500;
    line-height: 1.1;
    font-size: 18px;
}

.company-role-buttons .btn-toolbar {
    position: relative;
    z-index: 6;
}

.clx-widget-menu {
    width: 127px;
    height: 87px;
    background: #e51523;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 5px #b01721 solid;
    color: #ffffff;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    z-index: 5;
}

.clx-widget-menu .clx-widget-single-item {
    padding: 5px;
    position: relative;
    padding: 5px 5px;
    transition: height ease-in 0.25s, width ease-in 0.25s;
    -webkit-transition: height ease-in 0.25s, width ease-in 0.25s;
    cursor: pointer;
}

/* .clx-widget-menu .clx-widget-single-item:hover {
    background-color: rgba(000,000,000,0.1);
} */

.clx-widget-menu span {
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
}

.clx-widget-menu p {
    font-size: 10px;
    line-height: 1 !important;
    line-height: 10px;
    letter-spacing: normal;
    margin: 5px auto 0;
    text-transform: uppercase;
    width: 97px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    font-family: 'Lato', sans-serif;
}

.clx-app-wrap {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    right: 20px;
    top: auto;
    left: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #e51523;
    border: 5px #b01721 solid;
    color: #ffffff;
    z-index: 5;
    transition: height ease-in 0.25s, width ease-in 0.25s;
    -webkit-transition: height ease-in 0.25s, width ease-in 0.25s;
    box-sizing: border-box;
    width: 300px;
    height: 299px;
    font-size: 12px;
    font-family: 'Lato', sans-serif;
}

.clx-header-wrap {
    box-sizing: border-box;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px;
    margin-bottom: 2px;
    height: 34px;
}

.clx-header-wrap #clx-close-icon {
    width: 20px;
    margin: 0px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #690a10;
}

.clx-header-wrap p {
    position: relative;
    display: inline-block;
    font-size: 16px;
    align-self: flex-end;
    text-align: center;
    margin: 0;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    line-height: 1.4;
    font-family: 'Lato', sans-serif;
    vertical-align: baseline;
}

.clx-header-wrap #clx-calendar-icon {
    float: none;
    width: 25px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clxicon {
    font-size: 24px;
}

.clx-calendar-month {
    background-color: #ebebeb;
    text-align: center;
    font-size: 21px;
    color: #e51523;
    position: relative;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clx-calendar-month-next {
    color: black;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clx-calendar-month-previous {
    color: black;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
}

#clx-calendar-days-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    padding: 14px 16px;
    font-size: 16px;
    box-sizing: border-box;
    min-height: 213px;
    background: #ffffff;
    font-family: 'Lato', sans-serif;
    line-height: normal;
    vertical-align: baseline;
}

#clx-calendar-days-container .clx-calendar-weekday-label {
    color: #3a3a3a;
    text-align: center;
    padding: 0 4px 4px 4px;
    width: calc((100% / 7) - 0.1px);
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

#clx-calendar-days-container .clx-calendar-day-div {
    text-align: center;
    padding: 4px;
    width: calc((100% / 7) - 0.1px);
    box-sizing: border-box;
    color: #3a3a3a;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}

#clx-calendar-days-container .clx-calendar-past-day {
    color: #999999;
    background: #eeeeee;
    cursor: default;
    text-align: center;
    padding: 4px;
    width: calc((100% / 7) - 0.1px);
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

#clx-calendar-days-container .clx-calendar-day-empty {
    background: none;
    color: #ffffff;
    cursor: default;
    font-family: 'Lato', sans-serif;
}

.clx-bottom-middle {
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    top: auto;
    animation-fill-mode: both;
    bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .clx-widget-menu {
        transform-origin: bottom;
    }

    .mini-widget_default {
        transform: translate(5px, 5px);
        right: 0;
    }

    &.bounce,
    &.pulse,
    &.rubberBand,
    &.shake,
    &.swing,
    &.tada,
    &.wobble,
    &.jello,
    &.heartBeat {
        transform: translateX(-50%);
        transform: translateX(-50%) !important;
    }
}

.clx-bottom-left {
    right: auto;
    top: auto;
    left: 0;
    bottom: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .clx-widget-menu {
        transform-origin: left;
    }
}

.clx-bottom-right {
    right: 0;
    top: auto;
    left: auto;
    bottom: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .clx-widget-menu {
        transform-origin: right;
    }
}

.clx-top-right {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    bottom: auto;
    top: 20px;
    right: 0;
    left: auto;

    .clx-widget-menu {
        transform-origin: right;
    }
}

/* clx available times */
.clx-calendar-available-times {
    margin-top: 0px;
    background-color: transparent;
    text-align: center;
    color: #ffffff;
    position: relative;
    padding-bottom: 10px;
    overflow-y: scroll;
    height: 187px;
    box-sizing: content-box;
    touch-action: none;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        border-radius: 0px;
        background-color: #fff;
    }

    &::-webkit-scrollbar {
        width: 3px;
        background-color: #000;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        -webkit-box-shadow: none;
        background-color: #000;
    }
}

#clx-calendar-available-times-instruction {
    font-size: 12px;
}

#clx-calendar-available-times-day {
    font-weight: 500 !important;
    font-size: 18px !important;
    font-family: inherit !important;
    letter-spacing: inherit !important;
    margin-top: 5px;
    margin-bottom: 5px;
    color: inherit;
}

#clx-calendar-available-times-waiting {
    font-weight: 500 !important;
    font-size: 18px !important;
    font-family: inherit !important;
    letter-spacing: inherit !important;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    color: inherit;
}

#clx-available-times-scrollbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
}

.clx-available-time-item {
    color: #3a3a3a;
    background-color: #ebebeb;
    width: 48.3%;
    margin: 5px 0;
    padding: 5px;
    box-sizing: border-box;
    font-size: 16px;
    cursor: pointer;
    transition: background ease-in 0.25s, color ease-in 0.25s;
    display: block;
    font-family: 'Lato', sans-serif;
    line-height: 1.2;
}

/* .clx-available-time-item:hover {
    background: #841219 !important;
    color: #ffffff;
} */
#clx-available-time-choose-new-day {
    -webkit-appearance: button;
    background-color: #c21b25;
    color: #ffffff;
    text-transform: uppercase;
    border: none;
    text-align: center;
    padding: 10px 10px 10px 10px;
    width: 100%;
    max-width: 304px;
    font-size: 12px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 5px auto 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
    cursor: pointer;
    transition: background ease-in 0.25s;
    -webkit-transition: background ease-in 0.25s;
    zoom: 1;
}

#clx-drawer #clx-available-time-choose-new-day i,
#clx-available-time-choose-new-day span {
    display: inline-block;
    vertical-align: middle;
}

// Start Widget Preview for Widget Colors

/* clx contact step */
.clx-calendar-container.clx-container-contact {
    width: 346px !important;
    height: 450px !important;
}

.clx-contact-info {
    background-color: transparent;
    text-align: center;
    color: #ffffff;
    position: relative;
}

h2.clx-info-time-day {
    font-weight: 500 !important;
    font-size: 16px !important;
    font-family: inherit !important;
    letter-spacing: inherit !important;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: 10px 0 15px 0;
    color: inherit;
    font-family: 'Lato', sans-serif;
}

.clx-scheduler-questions-form form {
    display: block;
    margin: 0px;
    padding: 0px;
}

.preview-widget-container {
    .clx-calendar-container {
        &.clx-top-left,
        &.clx-top-right,
        &.clx-bottom-left,
        &.clx-bottom-middle,
        &.clx-bottom-right {
            width: 346px !important;
        }
    }
}

.clx-container-contact {
    .clx-scheduler-questions-form {
        height: 360px;
        overflow-y: auto;
        margin-right: 2px;
        padding: 0 10px;

        .clx-wrapper-label {
            label {
                color: #fff;
                display: flex;
                align-items: center;
                justify-self: flex-start;
            }
        }
    }
}

.clx-scheduler-question-container {
    margin-bottom: 5px;
}

.clx-scheduler-question-field {
    color: #3a3a3a;
    background: #ffffff;
    margin-bottom: 10px !important;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    border: none;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    line-height: 1.15;
}

.clx-scheduler-question-field-error {
    color: red;
    background: #ffffff;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    border: none;
    box-sizing: border-box;
    width: 100%;
    height: 29px;
    line-height: 1.15;
    zoom: 1;
}

.clx-scheduler-submit-button {
    background-color: #c21b25;
    color: #ffffff;
    text-transform: uppercase;
    border: none;
    text-align: center;
    padding: 10px 10px 10px 10px;
    width: 100%;
    font-size: 12px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    transition: background ease-in 0.25s;
    -webkit-transition: background ease-in 0.25s;
    height: 37px;
    line-height: 1.2;
    zoom: 1;
}

.clx-scheduler-field-error {
    color: yellow;
    font-size: 12px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 0;
}

//  Schedule Genie Calendar

.clx-top-left {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 0;
    bottom: auto;
    top: 20px;
    left: 0;
    right: auto;

    .clx-widget-menu {
        transform-origin: left;
    }
}

// End Widget Preview

.clx-widget-menu-wrapper {
    position: absolute;
}

.clx-widget-menu-wrapper .clx-widget-menu {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
}

/* skin1 */

.clx-widget-menu.skin1 {
    width: 341px;
    height: 72px;
}

.clx-widget-menu.skin1.expanded {
    width: 341px;
    height: auto;
    padding: 0;
    border-top: 0;
}

.clx-widget-menu.skin1 .clx-widget-single-item {
    display: flex;
    align-items: center;
    height: 100%;
}

.clx-widget-menu.skin1 .clx-widget-single-item .clxicon {
    font-size: 40px;
    display: flex;
    align-items: center;
    animation: none;
}

.clx-widget-menu.skin1 .clx-widget-single-item .clx-widget-title {
    margin: 0;
    height: auto;
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 12px;
    user-select: none;
}

.clx-widget-menu.skin1 .clx-widget-single-item .clx-widget-title br {
    display: none;
}

.clx-widget-menu-wrapper .more-btn {
    position: absolute;
    top: -43px;
    width: 100%;
    left: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 50px;
    z-index: 0;
    font-weight: 300;
    color: #ffffff;
    display: flex;
    justify-content: center;
    outline: none;
    cursor: pointer;
    zoom: 100%;
}

.clx-widget-menu-wrapper .more-btn span {
    font-size: 10px;
    text-transform: uppercase;
    position: relative;
    top: 20px;
    position: relative;
    display: block;
    width: 29px;
}

.clx-widget-menu-wrapper .more-btn span i {
    position: absolute;
    transform: rotate(-90deg);
    left: 0px;
    top: -29px;
    font-size: 30px;
}

.clx-widget-menu-wrapper.clx-top-right {
    top: 80px;
}

.clx-widget-menu-wrapper.clx-top-left {
    top: 80px;
}

.clx-widget-menu.skin1.expanded .clx-widget-single-item {
    transition: none;
    min-height: 65px;
    border: 5px solid;
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: calc(100% + 10px);
    margin-top: -3px;
    position: relative;
    left: -5px;
}

/* .clx-widget-menu.skin1.expanded .clx-widget-single-item:first-child {
    border-top: 0;
} */

/* .clx-widget-menu.skin1 .clx-widget-menu.skin1 .clx-widget-menu.skin1 .clx-widget-menu.skin1 .clx-widget-menu.skin1 .clx-widget-menu.skin1 */
.flex-right {
    display: flex;
    justify-content: flex-end;
}
