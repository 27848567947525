@import '../../css/functions';
@import '../../css/mixins';
@import '../../css/variables';

.home-dashboard {
    .action-list {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-start;
    }

    &-loading {
        position: absolute;
        top: 8px;
        left: 8px;
        .MuiCircularProgress-colorPrimary {
            color: color('primary_color');
        }
    }

    &-filter {
        &-row {
            display: flex;
            align-items: center;
        }
    }

    &-time-chart {
        .lc-chart-legend {
            padding-bottom: 0;
        }
    }

    &-bar-chart {
        .lc-chart-legend {
            padding-bottom: 0;
        }
    }

    &-chart {
        &-empty {
            svg {
                margin: 0 auto 24px;
            }

            &-description {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }
}

// Fixes
// Note: setting the z-index to 3 here allows chart tooltips to float above the side nav layout
.lc-chart-tooltip {
    z-index: 3;
}
