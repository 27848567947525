@import '../../css/functions';
@import '../../css/mixins';
@import '../../css/variables';

.company-details {
    &-members {
        &-search {
            width: 250px;
        }
    }

    &-trial {
        margin: 16px 32px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border: solid 1px color('light-gray');
        border-radius: 6px;
        flex-wrap: wrap;

        p {
            margin-bottom: 0;
        }
    }
}
