.audit-log-error-msg {
    text-align: center;
    margin-top: 100px;
    font-weight: bold;
    color: rgba(128, 128, 128, 0.568);
}

.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.drop-down-partner {
    margin-top: 10px;
}

.audit-logs-btn {
    margin: 10px;
}