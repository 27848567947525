@import '../../../../css/functions';
@import '../../../../css/mixins';
@import '../../../../css/variables';

.customer-lead-journey {
    &-row {
        margin-bottom: 16px;
        padding: 16px;
        border-radius: 8px;
        background-color: color('lightest-gray');

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-header {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    &-divider {
        margin: 16px 0;
        border-radius: 8px;
        border-top: 1px solid color('lighter-gray');
    }

    &-labels {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    &-label {
        padding: 12px;
        border: 1px solid color('light-gray');
        border-radius: 8px;
        background-color: color('white');

        &.first-touch,
        &.last-touch {
            color: color('white');
            background-color: color('purple');
            border: 1px solid color('purple');
        }

        &.conversion {
            color: color('white');
            background-color: color('dark-red');
            border: 1px solid color('dark-red');
        }

        &.device_location,
        &.device_type {
            background-color: color('green');
            border: 1px solid color('green');
        }

        &.referrer {
            background-color: color('light-red');
            border: 1px solid color('light-red');
        }

        &.apps {
            background-color: color('lightest-blue');
        }

        &.purple {
            color: color('white');
            background-color: color('purple');
            border: 1px solid color('purple');
        }

        &.red {
            color: color('white');
            background-color: color('dark-red');
            border: 1px solid color('dark-red');
        }

        &.cyan {
            background-color: color('lightest-blue');
        }

        &.light-red {
            background-color: color('light-red');
            border: 1px solid color('light-red');
        }

        &.generic-color {
            background-color: color('lightest-blue');
        }

        &.align-center {
            text-align: center;
        }

        &.bold {
            font-weight: 800;
        }
    }
}
