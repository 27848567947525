.clarity-schedule-report-modal {
    .lc-modal-header {
        margin-bottom: 0;
    }

    .MuiCardContent-root {
        overflow-y: scroll;
    }

    &-content {
        box-sizing: border-box;
        margin: 0 -24px;
    }

    [id*='tabpanel-'] {
        padding: 24px;
    }

    .lc-input-checkbox.MuiFormControlLabel-root {
        margin-bottom: -10px;
    }

    &-filters-tab {
        .lc-input-select .lc-input-text input[type='text']::placeholder {
            color: color('black') !important;
            opacity: 1;
        }
        .lc-input-select .lc-input-text input[type='text']::-moz-placeholder {
            color: color('black') !important;
            opacity: 1;
        }
        .lc-input-select .lc-input-text input[type='text']::-webkit-input-placeholder {
            color: color('black') !important;
            opacity: 1;
        }
    }
}
