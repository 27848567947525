@import '../../../../css/variables';
@import '../../../../css/functions';

.floorplans-list {
    &-item,
    &-header {
        display: grid;
        grid-auto-rows: auto;
        grid-row-gap: 8px;

        @media (min-width: 900px) {
            grid-template-columns: 20% 1fr 1fr 1fr 25%;
        }
    }

    &-header {
        @media (max-width: 899px) {
            display: none;
        }
    }

    &-item {
        > div {
            min-width: 0;
        }

        &-field {
            display: grid;
            grid-template-columns: 1fr 3fr;

            @media (min-width: 900px) {
                display: block;
            }

            &-label {
                color: color('bluegray');
                padding-right: 16px;

                @media (min-width: 900px) {
                    display: none;
                }
            }

            &-error {
                color: color('red');
                font-weight: 700;
            }
        }

        &-actions {
            text-align: right;
        }

        &-wrapper {
            & + & {
                margin-top: 12px;
            }
        }

        &-error {
            color: color('red');
            margin-top: 8px;
            margin-bottom: 12px;
        }

        &-has-error {
            border-color: color('red') !important;
        }
    }
}
