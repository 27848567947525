@import '../../css/variables';
@import '../../css/mixins';

.schedule-genie {
    &-widget-row {
        @include column-grid($gap: '16px');
    }
    &-content {
        @media #{$mq-min-lg} {
            @include column-grid();
        }
    }

    &-availability-row {
        display: flex;

        &-select {
            flex: 1;
        }

        &-link {
            margin-left: 16px;
        }
    }

    &-custom-time-slots {
        @include column-grid($gap: '16px');
    }

    &-checkbox {
        margin: -16px 0 16px;
    }

    &-slot-row {
        @media #{$mq-min-lg} {
            display: grid;
            grid-template-columns: 3fr 2fr;
            column-gap: 16px;
        }
    }

    &-calendar-config-button {
        width: 100%;
    }

    &-address-button {
        margin: 4px 0 24px;
    }
}
