@import '../../../../css/functions';
@import '../../../../css/variables';

.app-footer {
    box-sizing: content-box;
    background: white;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 16px;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);

    &-primary {
        border-top: 4px solid color('lighter-gray');
    }

    &-sticky {
        z-index: 10;
        position: sticky;
        bottom: 0;
    }

    &-full-width {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        border-radius: 0;
        border-top: 1px solid color('lighter-gray');
    }
}
