@import '../../../css/variables';
@import '../../../css/functions';

.top-bar {
    height: $top-bar-height;
    position: fixed;
    width: 100vw;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-bottom: solid 1px color('lighter-gray');
    box-shadow: 0 4px 4px rgba(43, 45, 55, 0.01);

    @media #{$mq-min-md} {
        padding: 8px 24px;
    }

    &-agency-name {
        color: color('darkest-gray');
        padding: 14px;
        display: inline-flex;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        margin: 0 -14px;
        border-radius: 6px;
        line-height: 20px;
        transition: var(--transition-fast);

        .lc-icon {
            margin: -3px 8px -14px 0;
            color: color('primary_color');
        }

        &:hover {
            background-color: color('lightest-gray');
        }
    }

    &-branding {
        padding: 0;
    }

    &-logo {
        width: auto;
        height: 72px - 32px;
    }

    &-loading {
        height: 16px;
        margin-left: 8px;
    }

    &-menu-button {
        margin-right: 16px;

        @media #{$mq-min-md} {
            display: none !important;
        }
    }

    &-profile {
        &.lc-button {
            color: color('darker-gray');
            background: transparent;
            border-color: transparent;
            text-transform: capitalize;

            &:hover {
                background-color: color('lightest-gray');

                .avatar {
                    background-color: color('lighter-gray');
                    color: color('darker-gray');
                }
            }

            .avatar {
                margin: 0 -4px 0 -8px;

                @media #{$mq-min-md} {
                    margin: 0 12px 0 -8px;
                }
            }
        }

        &-name {
            display: none;

            @media #{$mq-min-md} {
                display: inline;
            }
        }
    }
}

#skip-to-main-button {
    opacity: 0;

    &:focus {
        opacity: 1;
    }
}
