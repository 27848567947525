@import '../../../css/helpers';
@import '../../../css/functions';
@import '../../../css/variables';

.ReactModal__Body--open {
    overflow: hidden;
}

.lc-modal {
    padding: 8px;

    @media #{$mq-min-xs} {
        padding: 16px;
    }
    @media #{$mq-min-sm} {
        padding: 24px;
    }
    @media #{$mq-min-md} {
        padding: 32px;
    }
    @media #{$mq-min-lg} {
        padding: 48px;
    }
    @media #{$mq-min-xl} {
        padding: 80px;
    }

    .MuiCardContent-root {
        padding: 24px;
    }

    &-header {
        z-index: 10;
        position: sticky;
        top: -24px;
        padding: 24px 24px 20px;
        margin: -24px -24px 24px;
        background: color('white');
        border-bottom: 1px solid color('lighter-gray');
    }

    &-close {
        z-index: 20;
    }

    &-title {
        @extend .app-title;

        .lc-icon {
            position: relative;
            top: -2px;
            margin-right: 8px;
        }
    }

    &-close {
        position: absolute;
        top: 12px;
        right: 12px;
    }

    &-actions {
        z-index: 10;
        position: sticky;
        bottom: -24px;
        display: flex;
        background: color('white');
        border-top: 1px solid color('lighter-gray');
        margin: 24px -24px -24px;

        &.MuiCardActions-root {
            padding: 16px;
        }
    }
}
