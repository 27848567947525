@import '../../../css/variables';
@import '../../../css/functions';
@import '../../../css/mixins';

.lc-description-list {
    @include column-grid($count: 12, $gap: '16px');
    align-items: flex-end;
    width: 100%;

    @media #{$mq-min-sm} {
        gap: 20px;
    }

    @media #{$mq-min-md} {
        gap: 24px;
    }

    &__item {
        grid-column: span 12;

        &__label {
            margin: 0;
        }

        &__value {
            margin: 8px 0 0;
            padding: 8px 12px;
            background-color: color('lightest-gray');
            border-radius: 6px;
            word-wrap: break-word;

            @media #{$mq-min-sm} {
                margin-top: 12px;
                padding: 12px 16px;
            }
        }
    }

    @each $column-count in (1, 2, 3, 4) {
        &--columns-#{$column-count} &__item {
            @media #{$mq-min-md} {
                grid-column: span #{calc(12 / $column-count)};
            }
        }
    }

    &:empty {
        display: none;
    }
}