@import '../../../css/functions';

.agency-theme-preview {
    padding: 24px;
    border-radius: 8px;
    border: solid 1px color('lighter-gray');

    .lc-button {
        margin-right: 16px;
    }
}
