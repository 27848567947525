.navbar-default .navbar-brand {
    color: inherit;
}
.navbar-default .navbar-nav > li > a {
    color: inherit;
}
.navbar-nav.nav-stacked > li {
    float: none;
}

table.table {
    margin-bottom: 0;
}

/* Note: Changing 3rd party (bootstrap) class styles directly makes it difficult if you ever want to use the original
styles. I added :not(.include-cells) so that I can use that class to add the border back too all of the cells as
desired. In the future, we should scope styles like these to another className or the specific components that use
them. - Jake (jake@lambdacurry.dev) */
.table-bordered:not(.include-cells) > tbody > tr > td {
    border: none;
}
.table-bordered:not(.include-cells) > tbody > tr,
.table-bordered:not(.include-cells) > thead > tr {
    border-bottom: 1px solid #ddd;
}
.table-bordered:not(.include-cells) > thead > tr:last-child {
    border: none;
}
.table-bordered:not(.include-cells) > thead > tr > th {
    border: none;
}
.table-bordered:not(.include-cells) {
    border: none;
    border-top: 1px solid #ddd;
}

.error {
    color: red;
    padding: 5px;
}
.errorField {
    color: red;
}
