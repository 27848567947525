.invite-new-company-modal {
    max-width: 500px;

    .title {
        margin-top: 0;
    }

    &-content {
        display: flex;

        .lc-input-wrapper {
            width: 100%;
        }

        .lc-button {
            margin-left: 16px;
        }

        &-or {
            padding-right: 16px;
            color: rgba(0, 0, 0, 0.38);
        }
    }
}
